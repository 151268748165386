"use strict";

const $addToCartPlpModal = $(".add-to-cart-plp-modal");

/**
* Populates the product information in the modal
* @param {Object} productInfo - an object containing product information
* @param {string} productInfo.productName - the name of the product
* @param {string} productInfo.brand - the brand of the product
* @param {string} productInfo.manufacturerName - the manufacturer name of the product
* @param {Object} productInfo.images - an object containing different sizes of product images
* @param {number} productInfo.quantity - the quantity of the product
* @param {Object[]} productInfo.variationAttributes - an array of objects containing variation attributes of the product
*/
function populateModalWithProductInfo({ productName, brand, manufacturerName, images, quantity, variationAttributes }) {
    const $modalProductImage      = $(".js-modal-product-image");
    const $modalProductBrand      = $(".js-modal-product-brand");
    const $modalProductName       = $(".js-modal-product-name");
    const $modalProductQuantity   = $(".js-modal-product-quantity");
    const $modalProductAttributes = $(".js-modal-product-attributes");

    // Product Image
    if (images && images.small[0]) {
        const { url, alt, title } = images.small[0];
        $modalProductImage.attr({ "src": url, alt, title });
    }

    // Product Brand
    const productBrand = brand || manufacturerName;
    $modalProductBrand.text(productBrand);

    // Product Name
    $modalProductName.text(productName);

    // Product Quantity
    $modalProductQuantity.text(quantity);

    // Product Attributes
    $modalProductAttributes.empty();
    if (variationAttributes) {
        variationAttributes.forEach(element => {
            const { displayName, displayValue } = element;
            $modalProductAttributes.append(`<span class="d-flex">${displayName}: ${displayValue}</span>`);
        });
    }

    $addToCartPlpModal.modal("show");
}

/**
* Adds a click event listener to the close button in the cart PLP modal
*/
function closeCartPlpEventListener() {
    $(".js-close-modal").on("click", () => {
        $addToCartPlpModal.modal("hide");
    });
}

/**
* Initializes the after add to cart modal
*/
function initAfterAddToCartModal() {
    const $body = $("body");
    closeCartPlpEventListener();

    $body.on("product:afterAddToCart", function (event, { data: { cart: { items } }, pid, quantity }) {
        const isDesktop   = $(window).width() > 768;
        const currentItem = items.find(item => item.id == pid);

        // If on desktop show modal
        if (isDesktop) {
            populateModalWithProductInfo({ ...currentItem, quantity });
        }
    });
}

module.exports = {
    initAfterAddToCartModal,
};
