"use strict";

const debounce = require("lodash/debounce");
const endpoint = $(".custom-suggestions-wrapper").data("url");
const customSearchCategory = $(".custom-suggestion-category").data("category");
const mobileDiv = $("#mobile-capsule");
const minChars = 3;
const UP_KEY = 38;
const DOWN_KEY = 40;
const DIRECTION_DOWN = 1;
const DIRECTION_UP = -1;

$(window).on("load", function () {
    if (isMobileSearch()) {
        mobileDiv.addClass("custom-search-mobile d-lg-none w-100 px-4 pb-3 container");
    }
});
/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .custom-suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).siblings(".custom-suggestions-wrapper");
}

/**
 * Determines whether DOM element is inside the .custom-search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.custom-search-field element
 * @return {boolean} - Whether DOM element is inside  div.custom-search-mobile
 */
function isMobileSearch() {
    return window.innerWidth <= 768 || /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent);
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $("body").removeClass("modal-open");
    $("input.custom-search-field").siblings().attr("aria-hidden", "false");
    $(".custom-suggestions").removeClass("modal");
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch()) {
        $("body").addClass("modal-open");
        $("input.custom-search-field").siblings().attr("aria-hidden", "true");
        getSuggestionsWrapper(scope).find(".custom-suggestions").addClass("modal");
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $("input.custom-search-field").val("");
    clearModals();
    $(".custom-suggestions-wrapper").empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    const mobileSearchIcon = ".custom-search-mobile button.";
    const iconSearch = "fa-search";
    const iconSearchClose = "fa-close";

    if (action === "close") {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr("type", "button");
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr("type", "submit");
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    const $suggestionsWrapper = getSuggestionsWrapper(this).empty();

    $.spinner().stop();

    if (typeof (response) !== "object") {
        $suggestionsWrapper.append(response).show();
        $(this).siblings(".custom-reset-button").addClass("d-md-block");

        if (isMobileSearch()) {
            toggleSuggestionsIcon("close");
            applyModals(this);
        }

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        const suggestionsList = $(".custom-suggestions .item");
        if ($(suggestionsList).length) {
            $("input.custom-search-field").attr("aria-describedby", "search-result-count");
        } else {
            $("input.custom-search-field").removeAttr("aria-describedby");
        }
    } else {
        $suggestionsWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    let url = endpoint + encodeURIComponent($(scope).val());
    url = customSearchCategory ? url + "&category=" + encodeURIComponent(customSearchCategory) : url;
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: url,
            method: "GET",
            success: processResponse,
            error: function () {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon("search");
        $(scope).siblings(".custom-reset-button").removeClass("d-md-block");
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    let suggestionsList = $(".custom-suggestions .item");
    if (suggestionsList.filter(".selected").length === 0) {
        suggestionsList.first().addClass("selected");
        $("input.custom-search-field").each(function () {
            $(this).attr("aria-activedescendant", suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            let idx = index + direction;
            if ($(this).hasClass("selected")) {
                $(this).removeClass("selected");
                $(this).removeAttr("aria-selected");
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass("selected");
                    suggestionsList.eq(idx).attr("aria-selected", true);
                    $(this).removeProp("aria-selected");
                    $("input.custom-search-field").each(function () {
                        $(this).attr("aria-activedescendant", suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass("selected");
                    suggestionsList.first().attr("aria-selected", true);
                    $("input.custom-search-field").each(function () {
                        $(this).attr("aria-activedescendant", suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}
module.exports = function () {
    $("form[name=\"customSearch\"]").submit(function (e) {
        let suggestionsList = $(".custom-suggestions .item");
        if (suggestionsList.filter(".selected").length !== 0) {
            e.preventDefault();
            suggestionsList.filter(".selected").find("a")[0].click();
        }
    });

    $("input.custom-search-field").each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        const debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on("keyup focus", function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    $("body").on("click", function (e) {
        if (!$(".custom-suggestions").has(e.target).length && !$(e.target).hasClass("custom-search-field")) {
            $(".custom-suggestions").hide();
        }
    });

    $("body").on("click touchend", ".custom-search-mobile button.fa-close", function (e) {
        e.preventDefault();
        $(".custom-suggestions").hide();
        toggleSuggestionsIcon("customSearch");
        tearDownSuggestions();
    });

    $(".custom-search .custom-reset-button").on("click", function () {
        $(this).removeClass("d-md-block");
    });
};
