"use strict";

var $googleMap = $(".js-pickup-map");

/**
* Loads Google Maps script and exposes the initMap function globally when the API is ready
* @param {Object} $googleMap - the Google Map object
*/
function loadGoogleMapsScript() {
    const apiKey = $(".google-maps-api-key").val() === "null" ? null : $(".google-maps-api-key").val();
    const decodedKey = apiKey ? window.atob(apiKey) : null;
    const googleMapsApiSrc = `https://maps.googleapis.com/maps/api/js?key=${decodedKey}&callback=initMap`;
    const tag = document.createElement("script");
    const firstScriptTag = document.getElementsByTagName("script")[0];

    tag.defer = true;

    tag.src = googleMapsApiSrc;
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Expose the function globally when the API is ready.
    window.initMap = () => {
        initMap($googleMap);
    };
}

/**
* Initializes the Google Map
* @param {Object} $googleMap - jQuery object of the Google Map element
* @returns {Object} window.googleMap - the Google Map object
* @returns {Object} window.infoWindow - the Google Map InfoWindow object
*/
function initMap($googleMap) {
    if ($googleMap.length) {
        const lat = +$googleMap.data("initial-lat");
        const lng = +$googleMap.data("initial-lng");
        const mapHtmlElement = $googleMap[0];

        const center = {
            lat,
            lng
        };

        window.googleMap = new google.maps.Map(mapHtmlElement, {
            center,
            fullscreenControl: false,
            mapTypeControlOptions: { mapTypeIds: [] },
            scaleControl: true,
            scrollwheel: true,
            streetViewControl: false,
            zoom: 3,
        });

        window.infoWindow = new google.maps.InfoWindow();
    }
}

/**
* Adds markers to the google map
* @param {Object} storeInfo - Object containing store information
* @param {number} storeInfo.latitude - Latitude of the store
* @param {number} storeInfo.longitude - Longitude of the store
* @param {string} storeInfo.storeInfoHtml - HTML string of the store information
*/
function addMarkersToMap(storeInfo) {
    const { latitude, longitude, storeInfoHtml } = storeInfo;

    removeMarker($googleMap);

    const newMarker = createMarker(latitude, longitude);
    setMarkerListener(newMarker);
    setMarkerInfoWindow(storeInfoHtml);
    openMarkerInfoWindow(newMarker);

    centerMap(window.googleMap, latitude, longitude, 18);
}

/**
* Creates a marker for a given latitude and longitude
* @param {number} latitude - the latitude of the marker
* @param {number} longitude - the longitude of the marker
* @returns {google.maps.Marker} - the created marker
*/
function createMarker(latitude, longitude) {
    return new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        draggable: false,
        map: window.googleMap,
        position: {
            lat: +latitude,
            lng: +longitude
        }
    });
}

/**
* Sets a click listener to the marker
* @param {Object} marker - the marker to set the listener to
*/
function setMarkerListener(marker) {
    marker.addListener("click", function () {
        window.infoWindow.close();
        window.infoWindow.open(this.map, this);
    });
}

/**
* Sets the content of the marker info window
* @param {string} storeInfoHtml - HTML string containing store info
*/
function setMarkerInfoWindow(storeInfoHtml) {
    window.infoWindow.setContent(storeInfoHtml);
}

/**
* Opens the info window of a marker on the map
* @param {Object} marker - the marker object to open the info window for
*/
function openMarkerInfoWindow(marker) {
    window.infoWindow.open(window.googleMap, marker);
}

/**
* Removes a marker from the map
* @param {Object} $googleMap - the google map object
*/
function removeMarker($googleMap) {
    initMap($googleMap);
}

/**
* Centers the map to a given position and zoom level
* @param {Object} googleMapObj - the Google Maps object
* @param {number} lat - the latitude of the position
* @param {number} lng - the longitude of the position
* @param {number} zoom - the zoom level of the map
*/
function centerMap(googleMapObj, lat, lng, zoom) {
    const position = new google.maps.LatLng(lat, lng);

    googleMapObj.setCenter(position);
    googleMapObj.setZoom(zoom);
}

module.exports = {
    loadGoogleMapsScript,
    addMarkersToMap
};
