"use strict";

/* Constants */
const storeLocatorData = $(".js-states-and-cities-json");
const stateAndCityList = storeLocatorData.data("state-and-city-list");
const defaultState = storeLocatorData.data("default-state") || "";
const defaultCity = storeLocatorData.data("default-city") || "";
const { functions: { clearForm } } = require("../components/clientSideValidation");

/**
 * Returns the list of cities for a given state
 * @param {Object} $stateDropdown - jQuery object of the state dropdown
 * @returns {Array} - an array of cities for the given state
 */
function getCityList($stateDropdown) {
    const selectedState = $stateDropdown.find("option:selected").val();
    return stateAndCityList[selectedState] || [];
}

/**
 * Populates the city dropdown with the list of cities
 * @param {Array} cityList - List of cities
 * @param {Object} $cityDropdown - jQuery object of the city dropdown
 * @param {String} defaultCity - Default city to be selected
 */
function populateCityDropdown(cityList, $cityDropdown, defaultCity) {
    cityList.forEach(city => {
        const cityOptionHtml = `<option value="${city}" ${city === defaultCity ? "selected" : ""}>${city}</option>`;
        $cityDropdown.append(cityOptionHtml);
    });
}

/**
 * Event handler for when the state dropdown is changed
 */
function updateCitiesDropdownOnStateSelection() {
    $(document).on("change", ".js-current-state", function () {
        const $stateDropdown = $(this);
        const $citiesDropdown = $(".js-dropdown-cities");
        const cityList = getCityList($stateDropdown);

        $citiesDropdown.find("option:not(:first)").remove();
        populateCityDropdown(cityList, $citiesDropdown, defaultCity);
        $citiesDropdown.trigger("change");
    });
}

/**
* Sets up event listeners for address selector functionality
*/
function addressSelectorListeners() {
    $(".js-toggle-address-mode").on("click", function () {
        toggleAddressMode($(this));
    });

    updateCitiesDropdownOnStateSelection();
    displayErrorMessage();
}

function updateInputVerification() {
    $("#city").trigger("change");
}

/**
* Toggles between adding a new address and selecting an existing address
* @param {jQuery} $button - the button element that triggers the address mode toggle
*/
function toggleAddressMode($button) {
    const { addAddressManually, selectAddress, addAddressText } = $button.data();
    const newText = addAddressManually ? selectAddress : addAddressText;

    $button.data("add-address-manually", !addAddressManually);
    $(".js-manual-address-container").toggleClass("d-none", !addAddressManually);
    $(".js-select-address-container").toggleClass("d-none", addAddressManually);

    if (addAddressManually) {
        fillCityAndState();
    }

    updateFormFields(addAddressManually);
    $button.text(newText);
    updateInputVerification();
}

/**
* Updates form fields based on user input for address and address dropdowns
* @param {boolean} addAddressManually - flag to indicate whether user is adding address manually
*/
function updateFormFields(addAddressManually) {
    const $address = $("#address1");
    const $regionAndComuna = $("#region, #city");
    const $streetAndNumber = $("#address2, #postBox");
    const $addressDropdowns = $(".js-current-state, .js-dropdown-cities");
    const $addressForm = $(".address-form");

    clearForm($addressForm);

    // Remove manually inserted values on the "select" address mode
    if (!addAddressManually) {
        $streetAndNumber.val("");
        $regionAndComuna.val("");
    }

    $address
        .prop({
            required: !addAddressManually,
            readonly: addAddressManually,
            tabindex: addAddressManually ? -1 : 0
        })
        .toggleClass("readonly", addAddressManually);

    $regionAndComuna.prop("required", !addAddressManually);

    $streetAndNumber
        .prop({
            required: addAddressManually,
            readonly: !addAddressManually
        })
        .toggleClass("readonly", !addAddressManually);

    $addressDropdowns
        .prop("required", addAddressManually)
        .toggleClass("readonly", !addAddressManually);
}

/**
* Fills the city and state when editing the billing address
*/
function fillCityAndState() {
    const state = $("#region").val() || $(".js-current-state option").first().val();
    const city = $("#city").val();

    $(".js-current-state").val(state).trigger("change");
    $(".js-dropdown-cities").val(city);
}

function copyCityAndStateToAddress() {
    const selectedState = $(".js-current-state option:selected").not("[data-not-selectable]").val();
    const selectedCity = $(".js-dropdown-cities option:selected").not("[data-not-selectable]").val();
    const manualAddress = $("#postBox").val() + " " + $("#address2").val();

    $("#region").val(selectedState);
    $("#city").val(selectedCity);
    $("#address1").val(manualAddress);
}

/**
 * Sets the default state and city for the store locator
 */
function initDefaultStateAndCity() {
    if (defaultState && defaultCity) {
        $(".js-current-state").val(defaultState).trigger("change");
        $(".js-dropdown-cities").val(defaultCity);
    }
}

/**
* Validates the selected city and state inputs and returns a boolean indicating the validity
* @returns {boolean} - true if the city and state inputs are valid, false otherwise
*/
function validateCityAndState() {
    const $regionErrorSelect = $(".js-current-state");
    const $communeErrorSelect = $(".js-dropdown-cities");
    const regionId = $(".js-current-state option:selected");
    const communeId = $(".js-dropdown-cities option:selected");
    let isValid = true;

    if (!regionId.val() || ($regionErrorSelect.prop("required") && regionId.data("not-selectable"))) {
        $regionErrorSelect.addClass("is-invalid");
        $regionErrorSelect[0].scrollIntoView({ behavior: "smooth", block: "center" });
        isValid = false;
    }

    if (!communeId.val() || ($communeErrorSelect.prop("required") && communeId.data("not-selectable"))) {
        $communeErrorSelect.addClass("is-invalid");
        $communeErrorSelect[0].scrollIntoView({ behavior: "smooth", block: "center" });
        isValid = false;
    }

    return isValid;
}

function displayErrorMessage() {
    const $addressFormErrorMessageContainer = $(".js-address-form-error-message");

    $("body").on("address:submit:address", function (e, showError) {
        $addressFormErrorMessageContainer.toggleClass("d-none", showError);
    });
}

/**
* Initializes the address selector functionality
*/
function initAddressSelector() {
    addressSelectorListeners();
    initDefaultStateAndCity();
}

module.exports = {
    initAddressSelector,
    methods: {
        validateCityAndState,
        copyCityAndStateToAddress
    }
};
