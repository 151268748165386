"use strict";

module.exports = {
    maskRUT: function ($elem) {
        var $rutField      = $($elem);
        var mask          = "Z0.000.000-A";
        var rutOptions     = {
            onKeyPress : function () {
                $rutField.mask(mask, rutOptions);
            },
            translation : {
                "Z" : {
                    pattern  : /[0-9]/g,
                    optional : true
                },
                "A" : {
                    pattern  : /[0-9kK]/g,
                    optional : false
                }
            },
            reverse: true
        };
        
        $rutField.mask(mask, rutOptions);
    },
    maskPhone: function ($elem) {
        delete $.jMaskGlobals.translation["9"];
        $($elem).mask("+56 9 0000 0000", {
            translation: {
                "0": {
                    pattern  : /[0-9]/g 
                }
            }
        });
    },
    maskBirthdate: function ($elem) {
        $($elem).mask("00/00/0000");
    }
};
