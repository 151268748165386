const utilsServices = require("../utils/utilsServices");

/**
 * Check for a local storage variable successMessage and if it's there, show a toast at the top right of the screen.
 * @returns {void}
 */
function showSuccessfulMessage() {
    const shouldShowSuccessMessage = localStorage.getItem("successMessage");

    if (shouldShowSuccessMessage) {
        const $alert = $(".js-notification-alert");
        const successMessage = $alert.data("successful-message");

        utilsServices.createSuccessNotification(successMessage, $alert, true, 3000);

        //Clear the local storage item
        localStorage.removeItem("successMessage");
    }
}

module.exports = {
    initialize: function () {
        showSuccessfulMessage();
    },
    methods: {
        showSuccessfulMessage
    }
};
