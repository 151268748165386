"use strict";

module.exports = {
    showOutOfStockMessage: function () {
        $(".add-to-cart").each(function (_index, element) {
            const $addToCart = $(element);
            const isUnavailable = $addToCart.data("is-unavailable");

            $addToCart.closest(".product-detail").find(".js-pdp-image-carousel .no-stock-message").toggleClass("d-none", !isUnavailable);
        });

        $(".product-tile-add-to-cart").each(function (_index, element) {
            const $addToCart = $(element);
            const isUnavailable = $addToCart.data("is-unavailable");

            $addToCart.closest(".product-tile-wrapper").find(".no-stock-message").toggleClass("d-none", !isUnavailable);
        });
    }
};
