"use strict";

function initDisplayModalListeners() {
    const $removeRecipeModal = $(".js-region-selector-modal");

    $(".js-btn-change-region").on("click", function () {
        $(this).attr("data-trigger", true);
        $removeRecipeModal.modal("show");
    });

    $removeRecipeModal.on("hidden.bs.modal", function () {
        $(".js-btn-change-region").attr("data-trigger", false);
    });
}

function getCommuneList($form, value) {
    const optionsList = $form.data("options-list");
    const commune = optionsList.find((item) => item && item.name === value);
    const communeList = commune.sectorList || [];

    return communeList;
}

function populateCommuneSelector($form, communeList) {
    const $communeSelect = $form.find(".js-commune-select");
    $communeSelect.html("");

    for (let commune of communeList) {
        $communeSelect.append(`<option value="${commune.name}">${commune.name}</option>`);
    }
}

function initChangeRegionListeners() {
    $(".js-region-select").on("change", function () {
        const $this = $(this);
        const value = $this.val();
        const $form = $this.closest(".change-region-form");

        const communeList = getCommuneList($form, value);
        populateCommuneSelector($form, communeList);
    });

    $(".js-btn-save-region").on("click", function (e) {
        e.preventDefault();

        const $this = $(this);
        const url = $this.data("url");
        const $modal = $this.closest(".js-region-selector-modal");
        const $regionSelect = $modal.find(".js-region-select");
        const $communeSelect = $modal.find(".js-commune-select");
        const region = $regionSelect.val();
        const commune = $communeSelect.val();

        if (region && commune) {
            $.ajax({
                url: url,
                type: "post",
                data: { state: region, city: commune },
                success: function (data) {
                    if (data.success) {
                        $modal.modal("hide");
                        window.location.reload();
                    }
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    });
}

module.exports = {
    initDisplayModalListeners: initDisplayModalListeners,
    initChangeRegionListeners: initChangeRegionListeners
};
