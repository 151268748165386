"use strict";

var keyboardAccessibility = require("./keyboardAccessibility");
const { debounce } = require("lodash");

// CONSTANTS
const preventOverFlowClass = "menu-prevent-overflow";

/**
* Closes the dropdown menu and removes the top category
* @param {Element} element - the element that triggers the event
*/
var clearSelection = function (element) {
    $(element).closest(".dropdown").children(".dropdown-menu").children(".top-category")
        .detach();
    $(element).closest(".dropdown.show").children(".nav-link").attr("aria-expanded", "false");
    $(element).closest(".dropdown.show").children(".dropdown-menu").attr("aria-hidden", "true");
    $("div.menu-group > ul.nav.navbar-nav > li.nav-item > a").attr("aria-hidden", "false");
};

const _$elements = {
    $body: $("body"),
    $btnMenu: $("#btn-menu"),
    $logoHomeDesktop: $(".logo-home").first(),
    $iconOpenMenu: $("#icon-open-menu"),
    $iconCloseMenu: $("#icon-close-menu"),
    $menuHeader: $("#menu-header"),
    $header: $("header"),
    $headerMenu: $("#headermenu"),
    $headerContent: $(".header-content"),
    $mainContent: $("#maincontent"),
    $footer: $("footer"),
    $auxiliaryMenuDesktop: $(".auxiliary-menu-desktop"),
    $mainMenu: $(".main-menu")
};

/**
* Checks if the window width is greater than or equal to 940px
* @returns {boolean} - true if window width is greater than or equal to 940px, false otherwise
*/
const isDesktop = () => {
    return $(window).width() >= 940;
};

/**
* Sets the css properties of the header menu
* @param {Object} _$elements - jQuery elements of the header menu
* @param {number} headerHeight - the height of the header element
* @param {number} windowHeight - the height of the window
* @param {Object} left - the left offset of the logoHomeDesktop element
*/
const setHeaderAttr = () => {
    const headerHeight = _$elements.$header.height();
    const windowHeight = $(window).height();
    const { left } = _$elements.$logoHomeDesktop.length ? _$elements.$logoHomeDesktop.offset() : 0;

    _$elements.$headerMenu.css({
        "top": `${(headerHeight)}px`,
        "height": `${(windowHeight - headerHeight)}px`,
        "left": `${isDesktop() ? `${left}px` : "0px"}`
    });
};

/**
* Sets the css properties for the dropdown menu
* @param {Object} _$elements - the elements of the menu
* @param {Object} $ - the jQuery object
* @returns {void}
*/
const setSubMenuAttr = () => {
    const heightHeaderMenu = _$elements.$headerMenu.height();
    const headerSize = $("#content-header").width();
    const $menu = $(".menu-toggleable-left");
    const menuSize = $menu.width();
    const { left } = _$elements.$btnMenu.length ? _$elements.$btnMenu.offset() : 0;

    if (isDesktop()) {
        $(".dropdown-menu.submenu-header").css({
            "height": `${heightHeaderMenu}px`,
            "width": `${headerSize - (left/2 + menuSize)}px`,
            "left": "99%",
            "border-radius": "0 0 16px 0",
            "border": "none",
            "top":"0"
        });
    } else {
        $(".dropdown-menu.submenu-header").css({
            "width": "100%",
            "left": "0",
            "border-radius": "0 0 0 0",
            "border": "none",
            "top":"0",
        });


    }
};

/**
* Handles the mobile overflow of the menu
* @returns {void}
*/
const handleMenuMobileOverflow = () => {
    const $backButton = $(".submenu-header-btnback");
    const $navLink = $(".nav-link");
    const $navLinkL2 = $(".nav-link-l2");
    const $outerContainer = _$elements.$mainMenu.find(".container");

    $navLink.on("click", handleNavLinkClick);
    $navLinkL2.on("click", handleNavLinkL2Click);
    $backButton.on("click", handleBackButtonClick);

    /**
    * Handles the click event on the navigation link
    */
    function handleNavLinkClick() {
        $outerContainer.addClass("h-100");
    }

    /**
    * Handles the click event for the level 2 navigation links
    */
    function handleNavLinkL2Click() {
        const $this = $(this);
        const $parentLi = $this.parent();
        const $parentUl = $this.parents("ul.submenu-header");

        if ($parentLi.hasClass("show")) {
            $parentUl.css("overflow", "hidden");
        }
    }

    /**
    * Handles the click event of the back button
    * @returns {void}
    */
    function handleBackButtonClick() {
        $(".submenu-header:not(.submenu-last)").css("overflow", "unset");
        $(this).hasClass("js-menu-back") ? $outerContainer.addClass("h-100") : $outerContainer.removeClass("h-100");
    }
};

/**
* Sets the max-height on the main menu and navbar mobile menu
* @returns {void}
*/
const setMaxHeightOnMenuMobile = () => {
    const headerHeight = $("header").height();
    const $mainMenu = $(".main-menu");
    const clientHeight = $(window).height();

    const maxHeight = (!isDesktop()) ? `height: ${clientHeight - headerHeight}px)` : "unset";

    $mainMenu.css({ maxHeight });
};

/**
* Calculates the scrollbar padding of the body element
* @param {number} initialWidth - the initial width of the body element
* @returns {string} - the scrollbar padding of the body element in pixels
*/
/**
* Returns the difference between initial width and body width in px
* @param {number} initialWidth - the initial width of the element
* @returns {string} - the difference between initial width and body width in px
*/
const calculateScrollbarPadding  = (initialWidth) => {
    return `${Math.abs(initialWidth - _$elements.$body[0].clientWidth)}px`;
};

/**
* Opens the menu and adds necessary classes and styles
* @private
*/
const openMenu = () => {
    const initialBodyWidth = _$elements.$body[0].offsetWidth;

    _$elements.$headerMenu.removeClass("d-none").addClass("d-block active");
    _$elements.$mainContent.add(_$elements.$footer).addClass(`menu-open ${preventOverFlowClass}`);
    _$elements.$auxiliaryMenuDesktop.addClass("menu-prevent-pointer-events");
    _$elements.$body.addClass(preventOverFlowClass).css("padding-right", calculateScrollbarPadding(initialBodyWidth));
    _$elements.$iconOpenMenu.addClass("d-none");
    _$elements.$iconCloseMenu.removeClass("d-none");

    setHeaderAttr();
    setSubMenuAttr();
    setMaxHeightOnMenuMobile();
};

/**
* Hides the header menu, removes the open menu classes and shows the open menu icon.
* @param {Object} _$elements - Object containing all the elements of the menu
*/
const closeMenu = () => {
    _$elements.$headerMenu.removeClass("d-block active").addClass("d-none");
    $(".dropdown-menu.submenu-header").removeClass("d-block");
    _$elements.$mainContent.add(_$elements.$footer).removeClass(`menu-open ${preventOverFlowClass}`);
    _$elements.$auxiliaryMenuDesktop.removeClass("menu-prevent-pointer-events");
    $(_$elements.$body, _$elements.$page).removeClass(preventOverFlowClass).removeAttr("style");
    _$elements.$iconOpenMenu.removeClass("d-none");
    _$elements.$iconCloseMenu.addClass("d-none");
    _$elements.$btnMenu.removeClass("active");
};


/**
* Handles the scroll event for the auxiliary menu on desktop
* @returns {void}
*/
const hideAuxiliaryMenuOnScroll = () => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const isScrollingUp = prevScrollPos > currentScrollPos;

        _$elements.$auxiliaryMenuDesktop.css({
            top: isScrollingUp ? "0" : "-50px",
            position: isScrollingUp ? "sticky" : "absolute"
        });

        prevScrollPos = currentScrollPos;

        //Recalculate header height
        setHeaderAttr();
    };

    $(window).on("scroll", handleScroll);
};

module.exports = {
    /**
    * Sets the attributes of the header and submenu, hides the auxiliary menu on scroll, handles the overflow of the mobile menu and sets the maximum height of the mobile menu
    */
    init: () => {
        $(document).ready(() => {
            setSubMenuAttr();
            hideAuxiliaryMenuOnScroll();
            handleMenuMobileOverflow();
            setMaxHeightOnMenuMobile();
        });
    },
    listenEvents: () => {

        _$elements.$btnMenu.on("click", (el) => {
            el.stopImmediatePropagation();

            if (_$elements.$btnMenu.hasClass("active")) {
                closeMenu();
                _$elements.$btnMenu.removeClass("active");
            } else {
                openMenu();
                _$elements.$btnMenu.addClass("active");
            }
        });

        _$elements.$body.on("click", function ({target}) {
            if (!$(target).is("header, .desktop-menu *") && isDesktop()) {
                closeMenu();
            } else if (!$(target).is(".main-menu, .mobile-menu *") && !isDesktop()) {
                closeMenu();
            }
        });

        $(".menu-header-navbar-link").on("mouseenter", (el) => {
            if (isDesktop()) {
                $(".submenu-header").removeClass("d-block");
                $(".menu-header-navbar-link").removeClass("active");
                $(el.target).addClass("active");
                const idSubMenu = $(el.target).attr("id");

                $(`[aria-label="${idSubMenu}"]`).addClass("d-block");
            }
        });

        $(".submenu-header-btnback").on("click", (el) => {
            $(".submenu-header").removeClass("d-block");

            const elFather = $(el.target).closest("ul.submenu-header");

            elFather.removeClass("d-block");
        });

        $(".submenu-header-btnback").on("click", (el) => {
            if (!isDesktop()) {
                $(el.target).closest(".dropdown-menu.submenu-header").closest("li").removeClass("show");
            }
        });

        var headerBannerStatus = window.sessionStorage.getItem("hide_header_banner");
        $(".header-banner .close").on("click", function () {
            $(".header-banner").addClass("d-none");
            window.sessionStorage.setItem("hide_header_banner", "1");
        });

        if (!headerBannerStatus || headerBannerStatus < 0) {
            $(".header-banner").removeClass("d-none");
        }

        keyboardAccessibility(".main-menu .nav-link, .main-menu .dropdown-link",
            {
                40: function (menuItem) { // down
                    if (menuItem.hasClass("nav-item")) { // top level
                        $(".navbar-nav .show").removeClass("show")
                            .children(".dropdown-menu")
                            .removeClass("show");
                        menuItem.addClass("show").children(".dropdown-menu").addClass("show");
                        menuItem.find("ul > li > a")
                            .first()
                            .focus();
                    } else {
                        menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                        if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                            menuItem.parent().parent().find("li > a") // set focus to the first menuitem
                                .first()
                                .focus();
                        } else {
                            menuItem.next().children().first().focus();
                        }
                    }
                },
                39: function (menuItem) { // right
                    if (menuItem.hasClass("nav-item")) { // top level
                        menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                        $(this).attr("aria-expanded", "false");
                        menuItem.next().children().first().focus();
                    } else if (menuItem.hasClass("dropdown")) {
                        menuItem.addClass("show").children(".dropdown-menu").addClass("show");
                        $(this).attr("aria-expanded", "true");
                        menuItem.find("ul > li > a")
                            .first()
                            .focus();
                    }
                },
                38: function (menuItem) { // up
                    if (menuItem.hasClass("nav-item")) { // top level
                        menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                    } else if (menuItem.prev().length === 0) { // first menuItem
                        menuItem.parent().parent().removeClass("show")
                            .children(".nav-link")
                            .attr("aria-expanded", "false");
                        menuItem.parent().children().last().children() // set the focus to the last menuItem
                            .first()
                            .focus();
                    } else {
                        menuItem.prev().children().first().focus();
                    }
                },
                37: function (menuItem) { // left
                    if (menuItem.hasClass("nav-item")) { // top level
                        menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                        $(this).attr("aria-expanded", "false");
                        menuItem.prev().children().first().focus();
                    } else {
                        menuItem.closest(".show").removeClass("show")
                            .closest("li.show").removeClass("show")
                            .children()
                            .first()
                            .focus()
                            .attr("aria-expanded", "false");
                    }
                },
                27: function (menuItem) { // escape
                    var parentMenu = menuItem.hasClass("show")
                        ? menuItem
                        : menuItem.closest("li.show");
                    parentMenu.children(".show").removeClass("show");
                    parentMenu.removeClass("show").children(".nav-link")
                        .attr("aria-expanded", "false");
                    parentMenu.children().first().focus();
                }
            },
            function () {
                return $(this).parent();
            }
        );

        $(".dropdown:not(.disabled) [data-toggle=\"dropdown\"]")
            .on("click", function (e) {
                if (!isDesktop(this)) {
                    // copy parent element into current UL
                    var li = $("<li class=\"dropdown-item top-category\" role=\"button\"></li>");
                    var link = $(this).clone().removeClass("dropdown-toggle pl-4")
                        .removeAttr("data-toggle")
                        .removeAttr("aria-expanded")
                        .attr("aria-haspopup", "false");
                    li.append(link);
                    // copy navigation menu into view
                    $(this).parent().addClass("show");
                    $(this).attr("aria-expanded", "true");
                    $(this).parent().children(".dropdown-menu").find(".submenu-header-btnback").eq(0).after(li);
                    $("div.menu-group > ul.nav.navbar-nav > li.nav-item > a").attr("aria-hidden", "true");
                    e.preventDefault();

                    // Scroll to Top when clicking on mobile subcategory
                    if ($(this).hasClass("nav-link-l2")) {
                        $(".submenu-last:visible").length && $(".submenu-last:visible")[0].scrollIntoView(true);
                        _$elements.$body[0].scrollIntoView(true);
                    }
                }
            })
            .on("mouseenter", function () {
                if (isDesktop(this)) {
                    var eventElement = this;
                    $(".navbar-nav > li").each(function () {
                        if (!$.contains(this, eventElement)) {
                            $(this).find(".show").each(function () {
                                clearSelection(this);
                            });
                            if ($(this).hasClass("show")) {
                                $(this).removeClass("show");
                                $(this).children("ul.dropdown-menu").removeClass("show");
                                $(this).children(".nav-link").attr("aria-expanded", "false");
                            }
                        }
                    });
                    // need to close all the dropdowns that are not direct parent of current dropdown
                    $(this).parent().addClass("show");
                    $(this).siblings(".dropdown-menu").addClass("show");
                    $(this).attr("aria-expanded", "true");
                }
            })
            .parent()
            .on("mouseleave", function () {
                if (isDesktop(this)) {
                    $(this).removeClass("show");
                    $(this).children(".dropdown-menu").removeClass("show");
                    $(this).children(".nav-link").attr("aria-expanded", "false");
                }
            });

        $(".navbar>.close-menu>.close-button").on("click", function (e) {
            e.preventDefault();
            $(".menu-toggleable-left").removeClass("in");

            $(".navbar-toggler").focus();

            $(".main-menu").attr("aria-hidden", "true");
            $(".main-menu").siblings().attr("aria-hidden", "false");
            $("header").siblings().attr("aria-hidden", "false");
        });

        $(".navbar-nav").on("click", ".submenu-header-btnback", function (e) {
            e.preventDefault();
            clearSelection(this);
        });

        $(".navbar-nav").on("click", ".close-button", function (e) {
            e.preventDefault();
            $(".navbar-nav").find(".top-category").detach();
            $(".navbar-nav").find(".nav-menu").detach();
            $(".navbar-nav").find(".show").removeClass("show");
            $(".menu-toggleable-left").removeClass("in");

            $(".main-menu").siblings().attr("aria-hidden", "false");
            $("header").siblings().attr("aria-hidden", "false");
        });

        $(".navbar-toggler").click(function (e) {
            e.preventDefault();
            $(".main-menu").toggleClass("in");

            $(".main-menu").removeClass("d-none");
            $(".main-menu").attr("aria-hidden", "false");
            $(".main-menu").siblings().attr("aria-hidden", "true");
            $("header").siblings().attr("aria-hidden", "true");

            $(".main-menu .nav.navbar-nav .nav-link").first().focus();
        });

        keyboardAccessibility(".navbar-header .user",
            {
                40: function ($popover) { // down
                    if ($popover.children("a").first().is(":focus")) {
                        $popover.next().children().first().focus();
                    } else {
                        $popover.children("a").first().focus();
                    }
                },
                38: function ($popover) { // up
                    if ($popover.children("a").first().is(":focus")) {
                        $(this).focus();
                        $popover.removeClass("show");
                    } else {
                        $popover.children("a").first().focus();
                    }
                },
                27: function () { // escape
                    $(".navbar-header .user .popover").removeClass("show");
                    $(".user").attr("aria-expanded", "false");
                },
                9: function () { // tab
                    $(".navbar-header .user .popover").removeClass("show");
                    $(".user").attr("aria-expanded", "false");
                }
            },
            function () {
                var $popover = $(".user .popover li.nav-item");
                return $popover;
            }
        );

        $(".navbar-header .user").on("mouseenter focusin", function () {
            if ($(".navbar-header .user .popover").length > 0) {
                $(".navbar-header .user .popover").addClass("show");
                $(".user").attr("aria-expanded", "true");
            }
        });

        $(".navbar-header .user").on("mouseleave", function () {
            $(".navbar-header .user .popover").removeClass("show");
            $(".user").attr("aria-expanded", "false");
        });

        _$elements.$body.on("click", "#myaccount", function () {
            event.preventDefault();
            $(".navbar-header .user .popover").toggleClass("show");
        });

        $(window).on("resize", debounce(()=> {
            setHeaderAttr();
            setSubMenuAttr();
            setMaxHeightOnMenuMobile();
        }, 300));
    },
};
