"use strict";

const { Datepicker } = require("vanillajs-datepicker");
const spanishLocale = {
    es: {
        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        today: "Hoy",
        monthsTitle: "Meses",
        clear: "Borrar",
        weekStart: 1,
        format: "dd/mm/yyyy"
    }
};

const prevArrow = "<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path data-name='Icon ionic-ios-arrow-down' d='m5.713 8.002 5.957 6.05a1.152 1.152 0 0 1 0 1.615 1.121 1.121 0 0 1-1.593 0l-6.75-6.855a1.155 1.155 0 0 1-.033-1.577l6.777-6.9a1.118 1.118 0 0 1 1.593 0 1.152 1.152 0 0 1 0 1.615Z' fill='#e4002b'/></svg>";

const nextArrow = "<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path data-name='Icon ionic-ios-arrow-down' d='M10.287 7.998 4.33 1.948a1.152 1.152 0 0 1 0-1.615 1.121 1.121 0 0 1 1.593 0l6.75 6.855a1.155 1.155 0 0 1 .033 1.577l-6.777 6.9a1.118 1.118 0 0 1-1.593 0 1.152 1.152 0 0 1 0-1.615Z' fill='#e4002b'/></svg>";

Object.assign(Datepicker.locales, spanishLocale);

/**
* Formats the given date into a string
* @param {Date} date - the date to be formatted
* @returns {string} - the formatted date in the form of MM/DD/YYYY
*/
function formatDate(date) {
    try {
        if (typeof date === "string") {
            if (/[a-zA-Z]/.test(date)) {
                date = new Date();
            } else {
                date = new Date(date.indexOf("/") !== -1 ? date.split("/").reverse().join("-") : date);
            }
        }
    } catch (err) {
        date = new Date();
    }

    return date.toISOString().split("T")[0].split("-").reverse().join("/");
}

/**
* Formats the given date array
* @param {Array} dates - array of dates to be formatted
* @returns {Array} - array of formatted dates
*/
function formatDateArray(dates) {
    return dates.map(date => {
        return formatDate(date);
    });
}

/**
* Checks if the given date is valid
* @param {Date} date - the date to be checked
* @param {Array} weekdays - array of valid weekdays
* @returns {boolean} - whether the given date is valid
*/
function isDayValid(date, weekdays) {
    const day = new Date(date).getDay();
    return weekdays.includes(day);
}

/**
* Calculates the minimum start date for the custom datepicker
* @param {number} daysToDeliver - Number of days to deliver
* @param {Array} nonWorkingDays - Array of non working days
* @param {Array} nonWorkingDates - Array of non working dates
* @returns {Date} - The minimum start date
*/
function minStartDate(element) {
    const daysToDeliver    = +element.dataset.daysToDeliver || 0;
    const isStoreClosedNow = element.dataset.isStoreClosedNow == "true" || false;
    const nonWorkingDays  = JSON.parse(element.dataset.nonWorkingDays) ? Array.from(JSON.parse(element.dataset.nonWorkingDays)) : [];
    const nonWorkingDates = JSON.parse(element.dataset.nonWorkingDates) ? Array.from(JSON.parse(element.dataset.nonWorkingDates)) : [];
    const date = new Date();
    const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];

    date.setDate(date.getDate() + daysToDeliver);

    daysOfWeek.forEach(() => {
        const isNonWorkingDay = isDayValid(date, nonWorkingDays);
        if (isNonWorkingDay) {
            isDayValid(date.setDate(date.getDate() + 1), nonWorkingDays);
        }

        const formatedDate = formatDate(date);
        const formattedNonWorkingDays = formatDateArray(nonWorkingDates);

        if (formattedNonWorkingDays.includes(formatedDate)) {
            date.setDate(date.getDate() + 1);
        }
    });

    var now           = new Date();
    var isDateToday   = now.toDateString() === date.toDateString();

    //if the date is today, check if the store closeTime is after current time. If it's before, add one day to date
    if (isDateToday && isStoreClosedNow) {
        date.setDate(date.getDate() + 1);
    }

    return date;
}

/**
* Returns the notification message with the store schedule
* @param {string} dateString - the selected date in the format DD/MM/YYYY
* @param {Object} schedules - the store schedule object
* @param {string} notificationMessage - the notification message
* @returns {Object} - an object with the notification message
*/
function getNotificationDate(dateString, schedules, notificationMessage) {
    const rawDate = dateString.split("/");
    const [day, month, year] = rawDate;
    const date = new Date(year, month - 1, day);
    const weekDay = date.toLocaleDateString("en-US", { weekday: "long" });
    let storeSchedule;

    for (const schedule of Object.keys(schedules)) {
        if (schedule.toLocaleLowerCase() === weekDay.toLocaleLowerCase()) {
            storeSchedule = schedules[schedule];
            break; // Exit the loop if the day matches
        }
    }

    notificationMessage = notificationMessage.replace("{fecha seleccionada}", dateString);
    notificationMessage = notificationMessage.replace("{hora inicio}", storeSchedule.openTime);
    notificationMessage = notificationMessage.replace("{hora fin}", storeSchedule.closeTime);

    return { notificationMessage };
}

/**
* Initializes the custom datepicker component
* @param {string} selector - the selector of the element to be initialized
*/
function initCustomDatePicker(selector = ".ahumada-custom-date-picker") {
    const elements = document.querySelectorAll(selector);
    const elementsAreVisible = $(elements).is(":visible");
    const calendarInitClass = "date-picker-init";

    if (!elementsAreVisible) {
        return;
    }

    elements.forEach((element) => {
        let nonWorkingDays  = JSON.parse(element.dataset.nonWorkingDays) ? Array.from(JSON.parse(element.dataset.nonWorkingDays)) : [];
        let nonWorkingDates = JSON.parse(element.dataset.nonWorkingDates) ? Array.from(JSON.parse(element.dataset.nonWorkingDates)) : [];

        if (!Object.keys(nonWorkingDays).length) {
            nonWorkingDays = [];
        }

        if ($(element).hasClass(calendarInitClass)) {
            // eslint-disable-next-line no-unused-vars
            const datepicker = new Datepicker(element, {
                autohide: true,
                buttonClass: "btn",
                language: "es",
                orientation: "auto top",
                prevArrow: prevArrow,
                nextArrow: nextArrow,
                weekStart: 0,
                daysOfWeekDisabled: nonWorkingDays,
                minDate: minStartDate(element),
                datesDisabled: function (date) {
                    let isDateDisabled;
                    const dateFormatted = formatDate(date);
                    const nonWorkingDatesFormatted = formatDateArray(nonWorkingDates);
                    const nonWorkingDays = nonWorkingDatesFormatted.includes(dateFormatted);

                    if (nonWorkingDays) {
                        isDateDisabled = true;
                    } else {
                        isDateDisabled = false;
                    }

                    return isDateDisabled;
                }
            });

            $(element, ".pickup-method-list").removeClass(calendarInitClass);
        }
    });
}

module.exports = {
    initCustomDatePicker,
    methods: {
        getNotificationDate
    }
};
