"use strict";

/**
 * AutoComplete class for Google Places API
 * @class
 * @param {NodeList} targetInputs - list of input elements to apply autocomplete to
 * @param {function} callbackOnPlaceChanged - callback function to execute when place is changed
 */
function AutoComplete(targetInputs, callbackOnPlaceChanged) {
    const PLACE_FIELDS_CONFIGURATIONS = {
        address1: [
            { type: "street_number", attribute: "short_name" },
            { type: "route", attribute: "long_name", separator: " " }
        ],
        address2: [
            { type: "route", attribute: "long_name", separator: " " }
        ],
        postBox: [
            { type: "street_number", attribute: "short_name" }
        ],
        state: [
            { type: "administrative_area_level_1", attribute: "short_name" }
        ],
        city: [
            { type: "administrative_area_level_3", attribute: "long_name" }
        ],
        country: [
            { type: "country", attribute: "long_name" }
        ],
        countryCode: [
            { type: "country", attribute: "short_name" }
        ],
    };

    const $configurations = $("[data-autocomplete-configurations]");
    const fields = $configurations.data("fields");

    this.targets = targetInputs || document.querySelectorAll("[data-autocomplete-target]") || [];
    this.countryCode = $configurations.length ? $configurations.data("country-code").split(",") : "";
    this.apiKey = $configurations.length ? $configurations.data("place-api-key") : {};
    this.fields = fields ? fields.split(",") : ["address_component", "geometry"];
    this.callbackOnPlaceChanged = callbackOnPlaceChanged;
    this.addressModal = $("#addAddressModal .modal-body");

    const self = this;

    this.init = function () {
        if (window.google && window.google.maps) {
            this.initAutocomplete();
            return;
        }

        if (!this.apiKey) {
            return;
        }

        window.initAutocomplete = this.initAutocomplete.bind(this);
    };

    this.initAutocomplete = async function () {
        if (!this.targets) {
            return;
        }

        const [Places] = await Promise.all([google.maps.importLibrary("places")]);

        this.targets.forEach((element) => {
            const $element = $(element);

            if ($element.data("isInitialized")) {
                return;
            } else {
                $element.data("isInitialized", true);
                // If we have a modal length, apply the scroll to the modal element, if not apply to the window
                const scrollTarget = self.addressModal.length ? self.addressModal : $(window);
                scrollTarget.on("scroll", self.positionPacContainer);
            }

            // Add a listener for input on each element
            element.addEventListener("input", async () => {

                var now = new Date().getTime();   
                var diferenca = now - (window.googlePlaceUltimaExecucao ? window.googlePlaceUltimaExecucao : 0);
    
                if (($element.val().length >= (window.googlePlaceCantidad ? window.googlePlaceCantidad : 3)) && (diferenca >= (window.googlePlaceTiempo ? window.googlePlaceTiempo : 1000))) {
                    window.googlePlaceUltimaExecucao = now;
                    // Check if the autocomplete is not already instantiated
                    if (!$element.data("placesAutoComplete")) {
                        const autocomplete = new Places.Autocomplete(element, {
                            componentRestrictions: { country: self.countryCode },
                            fields: self.fields
                        });

                        autocomplete.element = element;
                        autocomplete.addListener("place_changed", self.onPlaceChanged);

                        // Store the autocomplete instance on the element for future reference
                        $element.data("placesAutoComplete", autocomplete);
                        self.watchPacContainer();
                    }
                } else {
                    // If the input has less than 3 characters, remove the autocomplete
                    if ($element.data("placesAutoComplete")) {
                        // Remove the place_changed listener
                        google.maps.event.clearInstanceListeners(element);
                        $element.data("placesAutoComplete", null);
                        $(".pac-container").remove();
                    }
                }
            });
        });
    };


    this.onPlaceChanged = function () {
        const place = this.getPlace();
        const address = self.getAddress(place);
        const $container = $(this.element).closest("form");

        if (!address) {
            return;
        }

        Object.keys(address).forEach((field) => {
            const $field = $container.find(`[data-autocomplete="${field}"]`);

            if ($field.length) {
                $field.val(address[field]);
            }
        });

        if (self.callbackOnPlaceChanged) {
            self.callbackOnPlaceChanged($container);
        }
    };

    this.getAddress = function (place) {
        if (!place || !place.address_components) {
            return;
        }

        const address = Object.keys(PLACE_FIELDS_CONFIGURATIONS).reduce((address, field) => {
            const fieldConfig = PLACE_FIELDS_CONFIGURATIONS[field];

            fieldConfig.forEach((config) => {
                const component = place.address_components.find((component) => component.types.includes(config.type));

                address[field] = component ? [address[field], component[config.attribute]].filter(Boolean).join(config.separator || " ") : "";
            });

            return address;
        }, {});

        if (place.geometry && place.geometry.location) {
            address.latitud = place.geometry.location.lat();
            address.longitud = place.geometry.location.lng();
        }


        return address;
    };

    this.watchPacContainer = function () {
        const targetNode = document.querySelector("body");

        // Options for the observer (which mutations to observe)
        const config = { childList: true, subtree: true };

        // Callback function to execute when mutations are observed
        const callback = (mutationList, observer) => {
            mutationList.forEach((mutation) => {
                if (mutation.target?.classList.contains("pac-container") && mutation.addedNodes?.length) {
                    self.positionPacContainer();
                    observer.disconnect();
                }
            });
        };

        // Create an observer instance with a callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    };

    this.positionPacContainer = function () {
        const target = self.targets[0];

        if (document.activeElement === target) {
            const { width, height } = target.getBoundingClientRect();
            const { left, top } = $(target).offset();
            const borderLeftWidth = parseFloat($(target).css("border-left-width").replace("px", ""));
            const borderRightWidth = parseFloat($(target).css("border-right-width").replace("px", ""));

            $(".pac-container").css({
                width: `${width - (borderLeftWidth + borderRightWidth)}px`,
                top: `${height + top}px`,
                left: `${left}px`,
                display: "block"
            });
        }
    };

}

async function validateAddressOnGoogleGeocoder() {
    const { Geocoder } = await google.maps.importLibrary("geocoding");
    let isAddressValid = false;

    const addressElements = ["#address2", "#postBox", "#region", "#city", "#country"];
    const address = addressElements.map(id => $(id).val()).join(" ");
    let $latitud = $("#latitud");
    let $longitud = $("#longitud");

    const geocoder = new Geocoder();
    await geocoder.geocode({ address }, function (result, status) {
        if (status === "OK" && !result[0]["partial_match"]) {
            $latitud.val(result[0].geometry.location.lat());
            $longitud.val(result[0].geometry.location.lng());
            isAddressValid = true;
        } else {
            $latitud.val("");
            $longitud.val("");
        }
    });

    $("body").trigger("address:submit:address", isAddressValid);
    return isAddressValid;
}

/**
 * Initializes an instance of the AutoComplete class and calls its init method
 * @param {Array} targetInputs - an array of input elements to attach the autocomplete functionality to
 * @param {Function} callbackOnPlaceChanged - a callback function to execute when the user selects a place from the autocomplete dropdown
 * @returns {void}
 */
function initAutocomplete(targetInputs, callbackOnPlaceChanged) {
    const autocomplete = new AutoComplete(targetInputs, callbackOnPlaceChanged);
    autocomplete.init();
}

module.exports = {
    initAutocomplete: initAutocomplete,
    validateAddressOnGoogleGeocoder
};
