"use strict";

var gtm = require("../js/gtm");

module.exports = {
    initGtmEvents: function () {
        if ($(".dashboard-section").length && $(".dashboard-section").attr("data-wasloggedin") === "true") {
            gtm.gtmLogin();
        }

        gtm.pageViewEvent();

        if ($(".product-detail").length) {
            gtm.viewItem();
        }

        if ($(".search-result-for").length) {
            gtm.gtmSearch();
        }

        if ($("#product-search-results").length) {
            gtm.viewItemList($(".product-grid"));
        }

        if ($(".cart-page").length && $(".product-info").length) {
            gtm.viewCart();
            localStorage.removeItem("wasPurchaseTriggered");
        }

        if ($(".data-checkout-stage").length && $(".data-checkout-stage").attr("data-wasloggedin") === "true") {
            gtm.gtmLogin();
            gtm.checkoutEvents("begin_checkout");
        }

        if ($(".order-thank-you-msg").length && !localStorage.getItem("wasPurchaseTriggered")) {
            gtm.checkoutEvents("purchase");
            localStorage.setItem("wasPurchaseTriggered", true);
        }

        $(".product-tile .tile-image, .product-tile .pdp-link").on("click", function () {
            const $product = $(this).closest(".product-tile");
            gtm.selectItem($product);
        });

    }
};
