"use strict";

var addressHelpers = require("base/checkout/address");
var formHelpers = require("base/checkout/formErrors");
var scrollAnimate = require("base/components/scrollAnimate");
const googlePlaceHelpers = require("ahumada/googlePlaces/googlePlaces");
const { initCustomDatePicker, methods: { getNotificationDate } } = require("../checkout/components/customDatepicker");
const { enableSubmitButtonWhenAllFieldsAreFilled, updateCountryInputValue } = require("../addressBook/addressBook");
const { loadGoogleMapsScript, addMarkersToMap } = require("../storeLocator/pickupStoreLocator");
var formValidation = require("../components/formValidation");
const { functions: { clearForm } } = require("../components/clientSideValidation");
const addressSelector = require("../addressBook/addressSelector");
const { validateAddressOnGoogleGeocoder } = require("../googlePlaces/googlePlaces");

/**
 * updates the shipping address selector within shipping forms
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} customer - the customer model
 */
function updateShippingAddressSelector(productLineItem, shipping, order, customer) {
    var uuidEl = $("input[value=" + productLineItem.UUID + "]");
    var shippings = order.shipping;

    var form;
    var $shippingAddressSelector;
    var hasSelectedAddress = false;

    if (uuidEl && uuidEl.length > 0) {
        form = uuidEl[0].form;
        $shippingAddressSelector = $(".addressSelector", form);
    }

    if ($shippingAddressSelector && $shippingAddressSelector.length === 1) {
        $shippingAddressSelector.empty();
        // Add New Address option
        $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            null,
            false,
            order
        ));

        if (customer.addresses && customer.addresses.length > 0) {
            $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
                order.resources.accountAddresses,
                false,
                order
            ));

            customer.addresses.forEach(function (address) {
                var isSelected = shipping.matchingAddressId === address.ID;
                $shippingAddressSelector.append(
                    addressHelpers.methods.optionValueForAddress(
                        { UUID: "ab_" + address.ID, shippingAddress: address },
                        isSelected,
                        order
                    )
                );
            });
        }
        // Separator -
        $shippingAddressSelector.append(addressHelpers.methods.optionValueForAddress(
            order.resources.shippingAddresses, false, order, { className: "multi-shipping" }
        ));
        shippings.forEach(function (aShipping) {
            var isSelected = shipping.UUID === aShipping.UUID;
            hasSelectedAddress = hasSelectedAddress || isSelected;
            var addressOption = addressHelpers.methods.optionValueForAddress(
                aShipping,
                isSelected,
                order,
                { className: "multi-shipping" }
            );

            var newAddress = addressOption.html() === order.resources.addNewAddress;
            var matchingUUID = aShipping.UUID === shipping.UUID;
            if ((newAddress && matchingUUID) || (!newAddress && matchingUUID) || (!newAddress && !matchingUUID)) {
                $shippingAddressSelector.append(addressOption);
            }
            if (newAddress && !matchingUUID) {
                $(addressOption[0]).remove();
            }
        });
    }

    if (!hasSelectedAddress) {
        // show
        $(form).addClass("hide-details");
    } else {
        $(form).removeClass("hide-details");
    }

    $("body").trigger("shipping:updateShippingAddressSelector", {
        productLineItem: productLineItem,
        shipping: shipping,
        order: order,
        customer: customer
    });
}

/**
 * updates the shipping address form values within shipping forms
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateShippingAddressFormValues(shipping) {
    var addressObject = $.extend({}, shipping.shippingAddress);

    if (!addressObject) {
        addressObject = {
            firstName: null,
            lastName: null,
            address1: null,
            address2: null,
            city: null,
            postalCode: null,
            stateCode: null,
            countryCode: null,
            phone: null
        };
    }

    addressObject.isGift = shipping.isGift;
    addressObject.giftMessage = shipping.giftMessage;

    $("input[value=" + shipping.UUID + "]").each(function (formIndex, el) {
        var form = el.form;
        if (!form) return;
        var countryCode = addressObject.countryCode;

        $("input[name$=_firstName]", form).val(addressObject.firstName);
        $("input[name$=_lastName]", form).val(addressObject.lastName);
        $("input[name$=_address1]", form).val(addressObject.address1);
        $("input[name$=_address2]", form).val(addressObject.address2);
        $("input[name$=_city]", form).val(addressObject.city);
        $("input[name$=_postalCode]", form).val(addressObject.postalCode);
        $("select[name$=_stateCode],input[name$=_stateCode]", form)
            .val(addressObject.stateCode);

        if (countryCode && typeof countryCode === "object") {
            $("select[name$=_country]", form).val(addressObject.countryCode.value);
        } else {
            $("select[name$=_country]", form).val(addressObject.countryCode);
        }

        $("input[name$=_phone]", form).val(addressObject.phone);

        $("input[name$=_isGift]", form).prop("checked", addressObject.isGift);
        $("textarea[name$=_giftMessage]", form).val(addressObject.isGift && addressObject.giftMessage ? addressObject.giftMessage : "");
    });

    $("body").trigger("shipping:updateShippingAddressFormValues", { shipping: shipping });
}

/**
 * updates the shipping method radio buttons within shipping forms
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateShippingMethods(shipping) {
    var uuidEl = $("input[value=" + shipping.UUID + "]");
    if (uuidEl && uuidEl.length > 0) {
        $.each(uuidEl, function (shipmentIndex, el) {
            var form = el.form;
            if (!form) return;

            var $shippingMethodList = $(".shipping-method-list", form);

            if ($shippingMethodList && $shippingMethodList.length > 0) {
                $shippingMethodList.empty();
                var shippingMethods = shipping.applicableShippingMethods;
                var selected = shipping.selectedShippingMethod || {};
                var shippingMethodFormID = form.name + "_shippingAddress_shippingMethodID";

                //
                // Create the new rows for each shipping method
                //
                $.each(shippingMethods, function (methodIndex, shippingMethod) {
                    if (shippingMethod.pickupInStoreMethod) {
                        return;
                    }
                    var tmpl = $("#shipping-method-template").clone();
                    var lineItemUUIDPart = "";

                    if ($(el).parents(".multi-shipping").length) {
                        lineItemUUIDPart = "-" + shipping.productLineItems.items[0].UUID;
                    }

                    if (!shippingMethod.allowDateInput) {
                        $(".ahumada-custom-date-picker-wrapper", tmpl).remove();
                    } else {
                        $(".ahumada-custom-date-picker", tmpl)
                            .attr("data-days-to-deliver", shippingMethod.daysToDeliver)
                            .attr("data-non-working-days", JSON.stringify(shippingMethod.nonWorkingDays))
                            .attr("data-non-working-dates", JSON.stringify(shippingMethod.nonWorkingDates))
                            .addClass("date-picker-init");
                    }

                    // set input
                    $("input:not(.ahumada-custom-date-picker)", tmpl)
                        .prop("id", "shippingMethod-" + shippingMethod.ID + "-" + shipping.UUID + lineItemUUIDPart)
                        .prop("name", shippingMethodFormID)
                        .prop("value", shippingMethod.ID)
                        .attr("checked", shippingMethod.ID === selected.ID);

                    $("label:not(.custom-date-picker-icon)", tmpl)
                        .prop("for", "shippingMethod-" + shippingMethod.ID + "-" + shipping.UUID + lineItemUUIDPart);
                    // set shipping method name
                    $(".display-name", tmpl).text(shippingMethod.displayName);
                    // add shipping method description
                    $(".shipping-description", tmpl).text(shippingMethod.description);
                    // set or hide arrival time
                    if (shippingMethod.estimatedArrivalTime) {
                        $(".arrival-time", tmpl)
                            .text("(" + shippingMethod.estimatedArrivalTime + ")")
                            .show();
                    }
                    // set shipping cost
                    $(".shipping-cost", tmpl).text(shippingMethod.shippingCost);
                    $shippingMethodList.append(tmpl.html());
                });
            }
        });
    }

    initCustomDatePicker();
    customDatePickerHandler();
    $("body").trigger("shipping:updateShippingMethods", { shipping: shipping });
}

/**
 * Update list of available shipping methods whenever user modifies shipping address details.
 * @param {jQuery} $shippingForm - current shipping form
 */
function updateShippingMethodList($shippingForm) {
    // delay for autocomplete!
    setTimeout(function () {
        var $shippingMethodList = $shippingForm.find(".shipping-method-list");
        var urlParams = addressHelpers.methods.getAddressFieldsFromUI($shippingForm);
        var shipmentUUID = $shippingForm.find("[name=shipmentUUID]").val();
        var url = $shippingMethodList.data("actionUrl");
        urlParams.shipmentUUID = shipmentUUID;

        $shippingMethodList.spinner().start();
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $("body").trigger("checkout:updateCheckoutView",
                        {
                            order: data.order,
                            customer: data.customer,
                            options: { keepOpen: true }
                        });

                    $shippingMethodList.spinner().stop();
                }
            }
        });
    }, 300);
}

/**
 * updates the order shipping summary for an order shipment model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 */
function updateShippingSummaryInformation(shipping, order) {
    $("[data-shipment-summary=" + shipping.UUID + "]").each(function (i, el) {
        var $container = $(el);
        var $shippingAddressLabel = $container.find(".shipping-addr-label");
        var $addressContainer = $container.find(".address-summary");
        var $shippingPhone = $container.find(".shipping-phone");
        var $methodTitle = $container.find(".shipping-method-title");
        var $methodArrivalTime = $container.find(".shipping-method-arrival-time");
        var $methodPrice = $container.find(".shipping-method-price");
        var $shippingSummaryLabel = $container.find(".shipping-method-label");
        var $summaryDetails = $container.find(".row.summary-details");
        var giftMessageSummary = $container.find(".gift-summary");

        var address = shipping.shippingAddress;
        var selectedShippingMethod = shipping.selectedShippingMethod;
        var isGift = shipping.isGift;

        addressHelpers.methods.populateAddressSummary($addressContainer, address);

        if (address && address.phone) {
            $shippingPhone.text(address.phone);
        } else {
            $shippingPhone.empty();
        }

        if (selectedShippingMethod) {
            $("body").trigger("shipping:updateAddressLabelText",
                { selectedShippingMethod: selectedShippingMethod, resources: order.resources, shippingAddressLabel: $shippingAddressLabel });
            $shippingSummaryLabel.show();
            $summaryDetails.show();
            $methodTitle.text(selectedShippingMethod.displayName);
            if (selectedShippingMethod.estimatedArrivalTime) {
                $methodArrivalTime.text(
                    "( " + selectedShippingMethod.estimatedArrivalTime + " )"
                );
            } else {
                $methodArrivalTime.empty();
            }
            $methodPrice.text(selectedShippingMethod.shippingCost);
        }

        if (isGift) {
            giftMessageSummary.find(".gift-message-summary").text(shipping.giftMessage);
            giftMessageSummary.removeClass("d-none");
        } else {
            giftMessageSummary.addClass("d-none");
        }
    });

    $("body").trigger("shipping:updateShippingSummaryInformation", { shipping: shipping, order: order });
}

/**
 * Update the read-only portion of the shipment display (per PLI)
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order model
 * @param {Object} [options] - options for updating PLI summary info
 * @param {Object} [options.keepOpen] - if true, prevent changing PLI view mode to "view"
 */
function updatePLIShippingSummaryInformation(productLineItem, shipping, order, options) {
    var $pli = $("input[value=" + productLineItem.UUID + "]");
    var form = $pli && $pli.length > 0 ? $pli[0].form : null;

    if (!form) return;

    var $viewBlock = $(".view-address-block", form);

    var address = shipping.shippingAddress || {};
    var selectedMethod = shipping.selectedShippingMethod;

    var nameLine = address.firstName ? address.firstName + " " : "";
    if (address.lastName) nameLine += address.lastName;

    var address1Line = address.address1;
    var address2Line = address.address2;

    var phoneLine = address.phone;

    var shippingCost = selectedMethod ? selectedMethod.shippingCost : "";
    var methodNameLine = selectedMethod ? selectedMethod.displayName : "";
    var methodArrivalTime = selectedMethod && selectedMethod.estimatedArrivalTime
        ? "(" + selectedMethod.estimatedArrivalTime + ")"
        : "";

    var tmpl = $("#pli-shipping-summary-template").clone();

    $(".ship-to-name", tmpl).text(nameLine);
    $(".ship-to-address1", tmpl).text(address1Line);
    $(".ship-to-address2", tmpl).text(address2Line);
    $(".ship-to-city", tmpl).text(address.city);
    if (address.stateCode) {
        $(".ship-to-st", tmpl).text(address.stateCode);
    }
    $(".ship-to-zip", tmpl).text(address.postalCode);
    $(".ship-to-phone", tmpl).text(phoneLine);

    if (!address2Line) {
        $(".ship-to-address2", tmpl).hide();
    }

    if (!phoneLine) {
        $(".ship-to-phone", tmpl).hide();
    }

    if (shipping.selectedShippingMethod) {
        $(".display-name", tmpl).text(methodNameLine);
        $(".arrival-time", tmpl).text(methodArrivalTime);
        $(".price", tmpl).text(shippingCost);
    }

    if (shipping.isGift) {
        $(".gift-message-summary", tmpl).text(shipping.giftMessage);
        var shipment = $(".gift-message-" + shipping.UUID);
        $(shipment).val(shipping.giftMessage);
    } else {
        $(".gift-summary", tmpl).addClass("d-none");
    }
    // checking h5 title shipping to or pickup
    var $shippingAddressLabel = $(".shipping-header-text", tmpl);
    $("body").trigger("shipping:updateAddressLabelText",
        { selectedShippingMethod: selectedMethod, resources: order.resources, shippingAddressLabel: $shippingAddressLabel });

    $viewBlock.html(tmpl.html());

    $("body").trigger("shipping:updatePLIShippingSummaryInformation", {
        productLineItem: productLineItem,
        shipping: shipping,
        order: order,
        options: options
    });
}

/**
 * Update the hidden form values that associate shipping info with product line items
 * @param {Object} productLineItem - the productLineItem model
 * @param {Object} shipping - the shipping (shipment model) model
 */
function updateProductLineItemShipmentUUIDs(productLineItem, shipping) {
    $("input[value=" + productLineItem.UUID + "]").each(function (key, pli) {
        var form = pli.form;
        $("[name=shipmentUUID]", form).val(shipping.UUID);
        $("[name=originalShipmentUUID]", form).val(shipping.UUID);

        $(form).closest(".card").attr("data-shipment-uuid", shipping.UUID);
    });

    $("body").trigger("shipping:updateProductLineItemShipmentUUIDs", {
        productLineItem: productLineItem,
        shipping: shipping
    });
}

/**
 * Update the shipping UI for a single shipping info (shipment model)
 * @param {Object} shipping - the shipping (shipment model) model
 * @param {Object} order - the order/basket model
 * @param {Object} customer - the customer model
 * @param {Object} [options] - options for updating PLI summary info
 * @param {Object} [options.keepOpen] - if true, prevent changing PLI view mode to "view"
 */
function updateShippingInformation(shipping, order, customer, options) {
    // First copy over shipmentUUIDs from response, to each PLI form
    order.shipping.forEach(function (aShipping) {
        aShipping.productLineItems.items.forEach(function (productLineItem) {
            updateProductLineItemShipmentUUIDs(productLineItem, aShipping);
        });
    });

    // Now update shipping information, based on those associations
    updateShippingMethods(shipping);
    updateShippingAddressFormValues(shipping);
    updateShippingSummaryInformation(shipping, order);

    // And update the PLI-based summary information as well
    shipping.productLineItems.items.forEach(function (productLineItem) {
        updateShippingAddressSelector(productLineItem, shipping, order, customer);
        updatePLIShippingSummaryInformation(productLineItem, shipping, order, options);
    });

    $("body").trigger("shipping:updateShippingInformation", {
        order: order,
        shipping: shipping,
        customer: customer,
        options: options
    });
}

/**
 * Update the checkout state (single vs. multi-ship)
 * @param {Object} order - checkout model to use as basis of new truth
 */
function updateMultiShipInformation(order) {
    var $checkoutMain = $("#checkout-main");
    var $checkbox = $("[name=usingMultiShipping]");
    var $submitShippingBtn = $("button.submit-shipping");
    $(".shipping-error .alert-danger").remove();

    if (order.usingMultiShipping) {
        $checkoutMain.addClass("multi-ship");
        $checkbox.prop("checked", true);
    } else {
        $checkoutMain.removeClass("multi-ship");
        $checkbox.prop("checked", null);
        $submitShippingBtn.prop("disabled", null);
    }

    $("body").trigger("shipping:updateMultiShipInformation", { order: order });
}

/**
  * Create an alert to display the error message
  * @param {Object} message - Error message to display
  */
function createErrorNotification(message) {
    var errorHtml = "<div class=\"alert alert-danger alert-dismissible valid-cart-error fade show\" role=\"alert\">" +
    "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">" +
    "<span aria-hidden=\"true\">&times;</span>" +
    "</button>" + message + "</div>";

    $(".shipping-error").append(errorHtml);
    scrollAnimate($(".shipping-error"));
}

/**
 * Handle response from the server for valid or invalid form fields.
 * @param {Object} defer - the deferred object which will resolve on success or reject.
 * @param {Object} data - the response data with the invalid form fields or
 *  valid model data.
 */
function shippingFormResponse(defer, data) {
    var formSelector = "#checkout-main";
    formHelpers.clearPreviousErrors(formSelector);

    // highlight fields with errors
    if (data.error) {
        if (data.redirectUrl) {
            window.location.href = data.redirectUrl;
            defer.reject(data);
            return;
        }
        if (data.fieldErrors.length) {
            data.fieldErrors.forEach(function (error) {
                if (Object.keys(error).length) {
                    formHelpers.loadFormErrors(formSelector, error);
                }
            });
            defer.reject(data);
        }

        if (data.serverErrors && data.serverErrors.length) {
            $.each(data.serverErrors, function (index, element) {
                createErrorNotification(element);
            });

            defer.reject(data);
        }

        if (data.cartError) {
            window.location.href = data.redirectUrl;
            defer.reject();
        }
    } else {
        // Populate the Address Summary

        $("body").trigger("checkout:updateCheckoutView", {
            order: data.order,
            customer: data.customer
        });
        scrollAnimate($(".payment-form"));
        defer.resolve(data);
    }
}
/**
 * Clear out all the shipping form values and select the new address in the drop down
 * @param {Object} order - the order object
 */
function clearShippingForms(order) {
    order.shipping.forEach(function (shipping) {
        $("input[value=" + shipping.UUID + "]").each(function (formIndex, el) {
            var form = el.form;
            if (!form) return;

            $("input[name$=_firstName]", form).val("");
            $("input[name$=_lastName]", form).val("");
            $("input[name$=_address1]", form).val("");
            $("input[name$=_address2]", form).val("");
            $("input[name$=_city]", form).val("");
            $("input[name$=_postalCode]", form).val("");
            $("select[name$=_stateCode],input[name$=_stateCode]", form).val("");
            $("select[name$=_country]", form).val("");

            $("input[name$=_phone]", form).val("");

            $("input[name$=_isGift]", form).prop("checked", false);
            $("textarea[name$=_giftMessage]", form).val("");
            $(form).find(".gift-message").addClass("d-none");

            $(form).attr("data-address-mode", "new");
            var addressSelectorDropDown = $(".addressSelector option[value=new]", form);
            $(addressSelectorDropDown).prop("selected", true);
        });
    });

    $("body").trigger("shipping:clearShippingForms", { order: order });
}

/**
 * Does Ajax call to create a server-side shipment w/ pliUUID & URL
 * @param {string} url - string representation of endpoint URL
 * @param {Object} shipmentData - product line item UUID
 * @returns {Object} - promise value for async call
 */
function createNewShipment(url, shipmentData) {
    $.spinner().start();
    return $.ajax({
        url: url,
        type: "post",
        dataType: "json",
        data: shipmentData
    });
}

/**
 * Does Ajax call to select shipping method
 * @param {string} url - string representation of endpoint URL
 * @param {Object} urlParams - url params
 * @param {Object} el - element that triggered this call
 */
function selectShippingMethodAjax(url, urlParams, el) {
    $.spinner().start();
    $("body").trigger("checkout:beforeShippingMethodSelected");

    $.ajax({
        url: url,
        type: "post",
        dataType: "json",
        data: urlParams
    })
        .done(function (data) {
            if (data.error) {
                window.location.href = data.redirectUrl;
            } else {
                $("body").trigger("checkout:updateCheckoutView",
                    {
                        order: data.order,
                        customer: data.customer,
                        options: { keepOpen: true },
                        urlParams: urlParams
                    }
                );
                $("body").trigger("checkout:postUpdateCheckoutView",
                    {
                        el: el
                    }
                );
            }
            $("body").trigger("checkout:shippingMethodSelected", data);
            $.spinner().stop();
        })
        .fail(function () {
            $.spinner().stop();
        });
}

/**
 * Hide and show to appropriate elements to show the multi ship shipment cards in the enter view
 * @param {jQuery} element - The shipping content
 */
function enterMultishipView(element) {
    element.find(".btn-enter-multi-ship").removeClass("d-none");

    element.find(".view-address-block").addClass("d-none");
    element.find(".shipping-address").addClass("d-none");
    element.find(".btn-save-multi-ship.save-shipment").addClass("d-none");
    element.find(".btn-edit-multi-ship").addClass("d-none");
    element.find(".multi-ship-address-actions").addClass("d-none");
}

/**
 * Hide and show to appropriate elements to show the multi ship shipment cards in the view mode
 * @param {jQuery} element - The shipping content
 */
function viewMultishipAddress(element) {
    element.find(".view-address-block").removeClass("d-none");
    element.find(".btn-edit-multi-ship").removeClass("d-none");

    element.find(".shipping-address").addClass("d-none");
    element.find(".btn-save-multi-ship.save-shipment").addClass("d-none");
    element.find(".btn-enter-multi-ship").addClass("d-none");
    element.find(".multi-ship-address-actions").addClass("d-none");
}

/**
 * Hide and show to appropriate elements that allows the user to edit multi ship address information
 * @param {jQuery} element - The shipping content
 */
function editMultiShipAddress(element) {
    // Show
    element.find(".shipping-address").removeClass("d-none");
    element.find(".btn-save-multi-ship.save-shipment").removeClass("d-none");

    // Hide
    element.find(".view-address-block").addClass("d-none");
    element.find(".btn-enter-multi-ship").addClass("d-none");
    element.find(".btn-edit-multi-ship").addClass("d-none");
    element.find(".multi-ship-address-actions").addClass("d-none");

    $("body").trigger("shipping:editMultiShipAddress", { element: element, form: element.find(".shipping-form") });
}

/**
 * perform the proper actions once a user has clicked enter address or edit address for a shipment
 * @param {jQuery} element - The shipping content
 * @param {string} mode - the address mode
 */
function editOrEnterMultiShipInfo(element, mode) {
    var form = $(element).closest("form");
    var root = $(element).closest(".shipping-content");

    $("body").trigger("shipping:updateDataAddressMode", { form: form, mode: mode });

    editMultiShipAddress(root);

    var addressInfo = addressHelpers.methods.getAddressFieldsFromUI(form);

    var savedState = {
        UUID: $("input[name=shipmentUUID]", form).val(),
        shippingAddress: addressInfo
    };

    root.data("saved-state", JSON.stringify(savedState));
}

/**
* Handles the click event on the delivery type containers
* @returns {void}
*/
function deliveryTypeListener() {
    var $deliveryTypes = $(".delivery-type-container");

    $deliveryTypes.on("click", function () {
        $(this).find("input").prop("checked", true);
    });
}

/**
* Handles the custom date picker
* @returns {void}
*/
function customDatePickerHandler() {
    const $datePickerInput = $(".ahumada-custom-date-picker:visible");

    $datePickerInput.each(function () {
        const $this = $(this);
        const isDisabled = $this.parents(".shipping-method-card").find(".custom-control-input").is(":checked");

        $this.prop("disabled", !isDisabled);
    });
}

/**
* Initializes the custom picker
*/
function initCustomPicker() {
    $("body").on("step:shipping", function () {
        initCustomDatePicker();
        customDatePickerHandler();
    });
}


/**
* Selects the store pickup shipping method
* @param {Object} el - jQuery element for the shipping-method-list
* @param {Object} $shippingForm - jQuery element for the shipping-form
* @param {string} url - URL for the select-shipping-method-url
* @param {string} shipmentUUID - UUID of the shipment
* @param {Object} urlParams - URL parameters for the address fields from UI
* @param {string} methodID - ID of the store pickup method
* @param {boolean} isGift - whether the shipment is a gift
* @param {string} giftMessage - gift message for the shipment
* @param {string} storeID - ID of the store
*/
function selectStorePickup() {
    var el = $(".shipping-method-list");
    var $shippingForm = $(".shipping-form").eq(1);
    var url = el.data("select-shipping-method-url");
    var shipmentUUID = $shippingForm.find("[name=shipmentUUID]").val();
    var urlParams = addressHelpers.methods.getAddressFieldsFromUI($shippingForm);
    urlParams.shipmentUUID = shipmentUUID;
    urlParams.methodID = $("#storePickup").val();
    urlParams.isGift = $shippingForm.find(".gift").prop("checked");
    urlParams.giftMessage = $shippingForm.find("textarea[name$=_giftMessage]").val();
    urlParams.storeID = $("input[name=\"store\"]:checked").val();
    selectShippingMethodAjax(url, urlParams, el);
}

/**
* Selects the store for pickup in store method
* @param {string} url - the URL to the store selection API
* @param {string} storeID - the ID of the selected store
* @returns {Promise} - a promise object that resolves with the store selection data
*/
function selectStore() {
    $.spinner().start();
    var url = $(".store-select").data("url");
    var storeID = $(".pickup-method-list input[type='radio']:checked").val();

    var defer = $.Deferred();

    $.ajax({
        url: url,
        type: "get",
        dataType: "json",
        data: { storeID: storeID }
    })
        .done(function (data) {
            defer.resolve(data);
        })
        .fail(function (err) {
            window.location.href = err.responseJSON.redirectUrl;
            defer.reject();
        })
        .always(function () {
            $.spinner().stop();
        });
    return defer.promise();

}

/**
* Event listener for store pickup selection
* @returns {void}
*/
function storePickupListener() {
    const $pickupMethodListInput = $(".pickup-method-list input[type='radio']");

    $pickupMethodListInput.on("change", function () {
        selectStore();
        customDatePickerHandler();
    });
}

/**
* Handles the tab change event and calls the customDatePickerHandler method
*/
function handleTabsChange() {
    $(".shipping-method-block").on("shown.bs.tab", function () {
        customDatePickerHandler();
        if ($("input[name=\"deliveryType\"]:checked").val() === $("#storePickup").val()) {
            selectStorePickup();
        } else {
            $(".shipping-method-card").first().find("input").trigger("click");
        }
    });
}

/**
* Opens the store locator modal and adds markers to the map
*/
function openStoreLocatorModal() {
    const $seeStoreButtons = $(".see-store-btn");
    const $modalElement = $(".store-pickup-map-modal");

    $seeStoreButtons.on("click", function () {
        const $this = $(this);
        const storeInfoHtml = $this.siblings(".store-information-container").html();
        const { latitude, longitude} = $this.data();

        $modalElement.modal("show");
        addMarkersToMap({ latitude, longitude, storeInfoHtml });
    });
}

/**
* Displays a notification message when a date is selected in the date picker
* @param {Object} $this - the date picker element
* @param {Object} storeSchedule - the store schedule object
* @param {string} $notificationMessage - the notification message
* @param {Object} date - the date object
* @param {Object} $notificationMessageContainer - the notification message container
*/
function pickupNotification() {
    $(".ahumada-custom-date-picker", ".pickup-method-list").on("changeDate", function () {
        const $this = $(this);
        const storeSchedule = $this.parents(".shipping-method-card").find(".store-schedule-container").data("schedule");
        const $notificationMessage = $this.parents(".shipping-method-card").find(".store-schedule-container").data("message");
        const date = getNotificationDate($this.val(), storeSchedule, $notificationMessage);
        const $notificationMessageContainer = $this.parents(".shipping-method-card").find(".pickup-notification-message");

        $notificationMessageContainer.append(
            `<div class="alert-success pickup-store-alert text-center" role="alert">
                ${date.notificationMessage}
            </div>`
        );

        setTimeout(function () {
            $(".pickup-store-alert").remove();
        }, 5000);
    });
}

/**
* Validates the custom datepicker input for a shipping method
* @returns {boolean} isValid - whether the datepicker input is valid or not
*/
function customDatepickerValidation() {
    let isValid = true;
    const $datePickerInput = $(".ahumada-custom-date-picker:visible");

    $datePickerInput.each(function () {
        const $this = $(this);
        const $datePickerInputInvalid = $this.siblings(".invalid-feedback");
        const datePickerInvalidText = $this.data("missing-error");
        const $datePickerRadioIsChecked = $this.parents(".shipping-method-card").find(".custom-control-input").is(":checked");

        if ($datePickerRadioIsChecked && $this.val() === "") {
            $this.addClass("is-invalid");
            $datePickerInputInvalid.text(datePickerInvalidText);
            $this[0].scrollIntoView({behavior: "smooth", block: "center"});

            isValid = false;
        } else {
            $this.removeClass("is-invalid");
            $datePickerInputInvalid.text("");
        }
    });

    // eslint-disable-next-line no-unused-vars
    return isValid;
}

/**
* Validates the personal info form
* @returns {boolean} - whether the form is valid or not
*/
function validatePersonalInfoForm() {
    const $personalInfoForm = $(".checkout-shipping-personalData-form");

    // Clear all previous errors
    clearForm($personalInfoForm);

    const isValid = $personalInfoForm[0].checkValidity();
    if (!isValid) {
        $personalInfoForm.find(".invalid-feedback").length &&
        $personalInfoForm.find(".invalid-feedback")[0].scrollIntoView({ behavior: "smooth", block: "center" });
    }

    return isValid;
}

/**
* Populates the cart address card with the given shipping address and customer info
* @param {Object} shippingAddress - the shipping address object
* @param {Object} customerInfo - the customer info object
*/
function populateCartAddressCard(shippingAddress, customerInfo) {
    const $addressContainerInfo = $(".address-container-info");
    const $addressContainerButton = $(".address-container-btn-content");
    const $addressContainerText = $(".address-container-text-description");
    const $addressContainer = $(".address-container");
    const fullAddress = shippingAddress.address2 + " " + shippingAddress.postBox + ", " + shippingAddress.city + ", " + shippingAddress.stateCode;

    // Change address Container Informations for Desktop and Mobile
    $addressContainerInfo.each(function () {
        const $this = $(this);
        $this.find("p").eq(0).text(shippingAddress.fullAddress ? shippingAddress.fullAddress : fullAddress);
        $this.find("p").eq(1).text(shippingAddress.phone);
    });

    const {
        addressId,
        firstName,
        lastName,
        address1,
        address2,
        rut,
        phone,
        city,
        postBox,
        stateCode,
        suite,
        latitud,
        longitud
    } = shippingAddress;

    $(".js-open-edit-address-modal, .js-cart-address").data({
        "address-id": addressId,
        "first-name": firstName,
        "last-name": lastName,
        "address1": address1,
        "address2": address2,
        "rut": rut,
        "phone": phone,
        "city": city,
        "post-box": postBox,
        "state-code": stateCode,
        "suite": suite ?? "",
        "latitud": latitud,
        "longitud": longitud
    });

    if (customerInfo) {
        const customerNameHtml = ` ${customerInfo.firstName ? "-" : ""} ${customerInfo.firstName} ${customerInfo.lastName}`;

        $addressContainerText.find(".address-container-updated-title").removeClass("d-none").find(".js-customer-name").html(customerNameHtml);
    }

    $addressContainerText.find(".address-container-title").addClass("d-none");
    $addressContainerText.find("h5").text(shippingAddress.fullAddress);
    $addressContainerText.find("p").text(shippingAddress.phone);
    $addressContainerButton.removeClass("w-100").addClass("align-self-start align-self-lg-center m-0");
    $addressContainerButton.parent().addClass("flex-row");
    $addressContainer.removeClass("noaddress");
    $addressContainerButton.find("button").addClass("d-none");
    $addressContainerButton.find("img").removeClass("d-none");
}

/**
* Handles the address select dropdown options
* @param {Object} shippingAddress - the address object
* @param {string} [formType=null] - the type of form
*/
function handleAddressSelectDropdownOptions(shippingAddress, formType = null) {
    const $addressSelect = $(".address");
    const $addressOptionToReplace = $addressSelect.find(`option[data-address-id="${shippingAddress.addressId}"]`);
    const optionTemplate = `
        <option selected
            data-address-id="${shippingAddress.addressId}"
            data-first-name="${shippingAddress.firstName}"
            data-last-name="${shippingAddress.lastName}"
            data-phone="${shippingAddress.phone}"
            data-rut="${shippingAddress.rut}"
            data-address1="${shippingAddress.address1}"
            data-address2="${shippingAddress.address2}"
            data-post-box="${shippingAddress.postBox}"
            data-city="${shippingAddress.city}"
            data-state-code="${shippingAddress.stateCode}"
            data-suite="${shippingAddress.suite}"
            data-latitud="${shippingAddress.latitud}"
            data-longitud="${shippingAddress.longitud}"
        >
            ${shippingAddress.firstName ?? shippingAddress.address.firstName} ${shippingAddress.lastName ?? shippingAddress.address.lastName} ${shippingAddress.address1 ?? shippingAddress.address.address1}
        </option>`;

    if (formType === "new") {
        $addressSelect.append(optionTemplate);
    } else {
        $addressOptionToReplace.replaceWith(optionTemplate);
    }
}

/**
* Submits the address form and updates the cart address card
* @param {string} editAddressId - the address ID to be edited
* @returns {boolean} false
*/
function submitCartAddress(editAddressId = "") {
    $("form.address-form").off().submit(async function (e) {
        e.preventDefault();
        const $form                           = $(this);
        const formType                        = $form.attr("data-type");
        let   url                             = $form.data("basket-update-shipping-address-url") + editAddressId;
        const $addressInput                   = $form.find("#address1");
        const $addressInputInvalidFeedback    = $addressInput.siblings(".invalid-feedback");
        const addressInputInvalidText         = $addressInput.data("invalid-location-error");
        const $modal                          = $("#addAddressModal");
        const isAddAddressManuallyEnabled     = $(".js-manual-address-container").length && !$(".js-manual-address-container").hasClass("d-none");
        let isAddressSelectorValid            = true;
        let isEnteredAddressValid             = true;

        $modal.spinner().start();

        $("form.address-form").trigger("address:submit", e);

        // Remove previous errors
        $("body").trigger("address:submit:address", true);

        if (isAddAddressManuallyEnabled) {
            addressSelector.methods.copyCityAndStateToAddress();
            isAddressSelectorValid = addressSelector.methods.validateCityAndState();
            isEnteredAddressValid = isAddressSelectorValid ? await validateAddressOnGoogleGeocoder() : false;
        } else {
            $("body").trigger("address:submit:address", true);
        }

        if (!isAddressSelectorValid || !isEnteredAddressValid) {
            $form.spinner().stop();
            return;
        }

        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: $form.serialize(),
            success: function (data) {
                $modal.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);

                    // add client side validation
                    if (data.isLocationInvalid) {
                        $addressInput.addClass("is-invalid");
                        $addressInputInvalidFeedback.text(addressInputInvalidText);
                        $addressInput[0].scrollIntoView({ behavior: "smooth", block: "center" });
                    }

                } else {
                    if (formType === "new") {
                        const shippingAddress = data.address;
                        const customerInfo = data.customer.profile;

                        populateCartAddressCard(shippingAddress, customerInfo);
                        handleAddressSelectDropdownOptions(shippingAddress, formType);

                    } else {
                        const shippingAddress = data.order.shipping[0].shippingAddress;
                        const customerInfo = data.order.customer;

                        populateCartAddressCard(shippingAddress, customerInfo);
                        handleAddressSelectDropdownOptions(shippingAddress, formType);
                    }

                    $modal.modal("hide");

                    $(".checkout-btn").toggleClass("disabled", !data.success);
                    $(".error-no-address").toggleClass("d-none", data.success);

                    return;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $modal.spinner().stop();
            }
        });

        return false;
    });
}

/**
* Initializes the address modal handlers
* @returns {void}
*/
function initAddressModalHandlers() {
    const $form = $(".address-form", ".modal");
    const addressId = $("#addressId").val();


    if ($form.length) {
        googlePlaceHelpers.initAutocomplete(null, function () {
            updateCountryInputValue();
            $form.trigger("change");
        });

        submitCartAddress(addressId.replace(/ /g, "%20"));
        enableSubmitButtonWhenAllFieldsAreFilled($form);
    }
}

module.exports = {
    methods: {
        updateShippingAddressSelector: updateShippingAddressSelector,
        updateShippingAddressFormValues: updateShippingAddressFormValues,
        updateShippingMethods: updateShippingMethods,
        updateShippingSummaryInformation: updateShippingSummaryInformation,
        updatePLIShippingSummaryInformation: updatePLIShippingSummaryInformation,
        updateProductLineItemShipmentUUIDs: updateProductLineItemShipmentUUIDs,
        updateShippingInformation: updateShippingInformation,
        updateMultiShipInformation: updateMultiShipInformation,
        shippingFormResponse: shippingFormResponse,
        createNewShipment: createNewShipment,
        selectShippingMethodAjax: selectShippingMethodAjax,
        updateShippingMethodList: updateShippingMethodList,
        clearShippingForms: clearShippingForms,
        editMultiShipAddress: editMultiShipAddress,
        editOrEnterMultiShipInfo: editOrEnterMultiShipInfo,
        createErrorNotification: createErrorNotification,
        viewMultishipAddress: viewMultishipAddress,
        customDatepickerValidation: customDatepickerValidation,
        initAddressModalHandlers: initAddressModalHandlers,
        populateCartAddressCard: populateCartAddressCard,
        validatePersonalInfoForm: validatePersonalInfoForm
    },

    handleStockServiceError: function () {
        const stockErrorElement = $(".js-stock-error");
        if (stockErrorElement.length && stockErrorElement.hasClass("show")) {
            if (history.scrollRestoration) {
                history.scrollRestoration = "manual";
            }
            scrollAnimate(stockErrorElement);
        }
    },

    selectShippingMethod: function () {
        var baseObj = this;

        $(".shipping-method-list").change(function () {
            var $shippingForm = $(this).parents("form");
            var methodID = $(":checked", this).val();
            var shipmentUUID = $shippingForm.find("[name=shipmentUUID]").val();
            var urlParams = addressHelpers.methods.getAddressFieldsFromUI($shippingForm);
            urlParams.shipmentUUID = shipmentUUID;
            urlParams.methodID = methodID;
            urlParams.isGift = $shippingForm.find(".gift").prop("checked");
            urlParams.giftMessage = $shippingForm.find("textarea[name$=_giftMessage]").val();

            var url = $(this).data("select-shipping-method-url");

            if (baseObj.methods && baseObj.methods.selectShippingMethodAjax) {
                baseObj.methods.selectShippingMethodAjax(url, urlParams, $(this));
            } else {
                selectShippingMethodAjax(url, urlParams, $(this));
            }
        });
    },

    toggleMultiship: function () {
        var baseObj = this;

        $("input[name='usingMultiShipping']").on("change", function () {
            var url = $(".multi-shipping-checkbox-block form").attr("action");
            var usingMultiShip = this.checked;

            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: { usingMultiShip: usingMultiShip },
                success: function (response) {
                    if (response.error) {
                        window.location.href = response.redirectUrl;
                    } else {
                        $("body").trigger("checkout:updateCheckoutView", {
                            order: response.order,
                            customer: response.customer
                        });

                        if ($("#checkout-main").data("customer-type") === "guest") {
                            if (baseObj.methods && baseObj.methods.clearShippingForms) {
                                baseObj.methods.clearShippingForms(response.order);
                            } else {
                                clearShippingForms(response.order);
                            }
                        } else {
                            response.order.shipping.forEach(function (shipping) {
                                $("input[value=" + shipping.UUID + "]").each(function (formIndex, el) {
                                    var form = el.form;
                                    if (!form) return;

                                    $(form).attr("data-address-mode", "edit");
                                    var addressSelectorDropDown = $(form).find(".addressSelector option[value=\"ab_" + shipping.matchingAddressId + "\"]");
                                    $(addressSelectorDropDown).prop("selected", true);
                                    $("input[name$=_isGift]", form).prop("checked", false);
                                    $("textarea[name$=_giftMessage]", form).val("");
                                    $(form).find(".gift-message").addClass("d-none");
                                });
                            });
                        }

                        if (usingMultiShip) {
                            $("body").trigger("shipping:selectMultiShipping", { data: response });
                        } else {
                            $("body").trigger("shipping:selectSingleShipping", { data: response });
                        }
                    }

                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    selectSingleShipping: function () {
        $("body").on("shipping:selectSingleShipping", function () {
            $(".single-shipping .shipping-address").removeClass("d-none");
        });
    },

    selectMultiShipping: function () {
        var baseObj = this;

        $("body").on("shipping:selectMultiShipping", function (e, data) {
            $(".multi-shipping .shipping-address").addClass("d-none");

            data.data.order.shipping.forEach(function (shipping) {
                var element = $(".multi-shipping .card[data-shipment-uuid=\"" + shipping.UUID + "\"]");

                if (shipping.shippingAddress) {
                    if (baseObj.methods && baseObj.methods.viewMultishipAddress) {
                        baseObj.methods.viewMultishipAddress($(element));
                    } else {
                        viewMultishipAddress($(element));
                    }
                } else {
                    /* eslint-disable no-lonely-if */
                    if (baseObj.methods && baseObj.methods.enterMultishipView) {
                        baseObj.methods.enterMultishipView($(element));
                    } else {
                        enterMultishipView($(element));
                    }
                    /* eslint-enable no-lonely-if */
                }
            });
        });
    },

    selectSingleShipAddress: function () {
        $(".single-shipping .addressSelector").on("change", function () {
            var form = $(this).parents("form")[0];
            var selectedOption = $("option:selected", this);
            var attrs = selectedOption.data();
            var shipmentUUID = selectedOption[0].value;
            var originalUUID = $("input[name=shipmentUUID]", form).val();
            var element;
            Object.keys(attrs).forEach(function (attr) {
                element = attr === "countryCode" ? "country" : attr;
                $("[name$=" + element + "]", form).val(attrs[attr]);
            });
            $("[name$=stateCode]", form).trigger("change");
            if (shipmentUUID === "new") {
                $(form).attr("data-address-mode", "new");
                $(form).find(".shipping-address-block").removeClass("d-none");
            } else if (shipmentUUID === originalUUID) {
                $(form).attr("data-address-mode", "shipment");
            } else if (shipmentUUID.indexOf("ab_") === 0) {
                $(form).attr("data-address-mode", "customer");
            } else {
                $(form).attr("data-address-mode", "edit");
            }
        });
    },

    selectMultiShipAddress: function () {
        var baseObj = this;

        $(".multi-shipping .addressSelector").on("change", function () {
            var form = $(this).closest("form");
            var selectedOption = $("option:selected", this);
            var attrs = selectedOption.data();
            var shipmentUUID = selectedOption[0].value;
            var originalUUID = $("input[name=shipmentUUID]", form).val();
            var pliUUID = $("input[name=productLineItemUUID]", form).val();
            var createNewShipmentScoped = baseObj.methods && baseObj.methods.createNewShipment ? baseObj.methods.createNewShipment : createNewShipment;

            var element;
            Object.keys(attrs).forEach(function (attr) {
                if (attr === "isGift") {
                    $("[name$=" + attr + "]", form).prop("checked", attrs[attr]);
                    $("[name$=" + attr + "]", form).trigger("change");
                } else {
                    element = attr === "countryCode" ? "country" : attr;
                    $("[name$=" + element + "]", form).val(attrs[attr]);
                }
            });

            if (shipmentUUID === "new" && pliUUID) {
                var createShipmentUrl = $(this).attr("data-create-shipment-url");
                createNewShipmentScoped(createShipmentUrl, { productLineItemUUID: pliUUID })
                    .done(function (response) {
                        $.spinner().stop();
                        if (response.error) {
                            if (response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                            return;
                        }

                        $("body").trigger("checkout:updateCheckoutView",
                            {
                                order: response.order,
                                customer: response.customer,
                                options: { keepOpen: true }
                            }
                        );

                        $(form).attr("data-address-mode", "new");
                    })
                    .fail(function () {
                        $.spinner().stop();
                    });
            } else if (shipmentUUID === originalUUID) {
                $("select[name$=stateCode]", form).trigger("change");
                $(form).attr("data-address-mode", "shipment");
            } else if (shipmentUUID.indexOf("ab_") === 0) {
                var url = $(form).attr("action");
                var serializedData = $(form).serialize();
                createNewShipmentScoped(url, serializedData)
                    .done(function (response) {
                        $.spinner().stop();
                        if (response.error) {
                            if (response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                            return;
                        }

                        $("body").trigger("checkout:updateCheckoutView",
                            {
                                order: response.order,
                                customer: response.customer,
                                options: { keepOpen: true }
                            }
                        );

                        $(form).attr("data-address-mode", "customer");
                        var $rootEl = $(form).closest(".shipping-content");
                        editMultiShipAddress($rootEl);
                    })
                    .fail(function () {
                        $.spinner().stop();
                    });
            } else {
                var updatePLIShipmentUrl = $(form).attr("action");
                var serializedAddress = $(form).serialize();
                createNewShipmentScoped(updatePLIShipmentUrl, serializedAddress)
                    .done(function (response) {
                        $.spinner().stop();
                        if (response.error) {
                            if (response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                            return;
                        }

                        $("body").trigger("checkout:updateCheckoutView",
                            {
                                order: response.order,
                                customer: response.customer,
                                options: { keepOpen: true }
                            }
                        );

                        $(form).attr("data-address-mode", "edit");
                    })
                    .fail(function () {
                        $.spinner().stop();
                    });
            }
        });
    },

    updateShippingList: function () {
        var baseObj = this;

        $("select[name$='shippingAddress_addressFields_states_stateCode']")
            .on("change", function (e) {
                if (baseObj.methods && baseObj.methods.updateShippingMethodList) {
                    baseObj.methods.updateShippingMethodList($(e.currentTarget.form));
                } else {
                    updateShippingMethodList($(e.currentTarget.form));
                }
            });
    },

    updateDataAddressMode: function () {
        $("body").on("shipping:updateDataAddressMode", function (e, data) {
            $(data.form).attr("data-address-mode", data.mode);
        });
    },

    enterMultiShipInfo: function () {
        var baseObj = this;

        $(".btn-enter-multi-ship").on("click", function (e) {
            e.preventDefault();

            if (baseObj.methods && baseObj.methods.editOrEnterMultiShipInfo) {
                baseObj.methods.editOrEnterMultiShipInfo($(this), "new");
            } else {
                editOrEnterMultiShipInfo($(this), "new");
            }
        });
    },

    editMultiShipInfo: function () {
        var baseObj = this;

        $(".btn-edit-multi-ship").on("click", function (e) {
            e.preventDefault();

            if (baseObj.methods && baseObj.methods.editOrEnterMultiShipInfo) {
                baseObj.methods.editOrEnterMultiShipInfo($(this), "edit");
            } else {
                editOrEnterMultiShipInfo($(this), "edit");
            }
        });
    },

    saveMultiShipInfo: function () {
        var baseObj = this;

        $(".btn-save-multi-ship").on("click", function (e) {
            e.preventDefault();

            // Save address to checkoutAddressBook
            var form = $(this).closest("form");
            var $rootEl = $(this).closest(".shipping-content");
            var data = $(form).serialize();
            var url = $(form).attr("action");

            $rootEl.spinner().start();
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: data
            })
                .done(function (response) {
                    formHelpers.clearPreviousErrors(form);
                    if (response.error) {
                        if (response.fieldErrors && response.fieldErrors.length) {
                            response.fieldErrors.forEach(function (error) {
                                if (Object.keys(error).length) {
                                    formHelpers.loadFormErrors(form, error);
                                }
                            });
                        } else if (response.serverErrors && response.serverErrors.length) {
                            $.each(response.serverErrors, function (index, element) {
                                createErrorNotification(element);
                            });
                        } else if (response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                        }
                    } else {
                        // Update UI from response
                        $("body").trigger("checkout:updateCheckoutView",
                            {
                                order: response.order,
                                customer: response.customer
                            }
                        );

                        if (baseObj.methods && baseObj.methods.viewMultishipAddress) {
                            baseObj.methods.viewMultishipAddress($rootEl);
                        } else {
                            viewMultishipAddress($rootEl);
                        }
                    }

                    if (response.order && response.order.shippable) {
                        $("button.submit-shipping").attr("disabled", null);
                    }

                    $rootEl.spinner().stop();
                })
                .fail(function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }

                    $rootEl.spinner().stop();
                });

            return false;
        });
    },

    cancelMultiShipAddress: function () {
        var baseObj = this;

        $(".btn-cancel-multi-ship-address").on("click", function (e) {
            e.preventDefault();

            var form = $(this).closest("form");
            var $rootEl = $(this).closest(".shipping-content");
            var restoreState = $rootEl.data("saved-state");

            // Should clear out changes / restore previous state
            if (restoreState) {
                var restoreStateObj = JSON.parse(restoreState);
                var originalStateCode = restoreStateObj.shippingAddress.stateCode;
                var stateCode = $("[name$=_stateCode]", form).val();

                if (baseObj.methods && baseObj.methods.updateShippingAddressFormValues) {
                    baseObj.methods.updateShippingAddressFormValues(restoreStateObj);
                } else {
                    updateShippingAddressFormValues(restoreStateObj);
                }

                if (stateCode !== originalStateCode) {
                    $("[data-action=save]", form).trigger("click");
                } else {
                    $(form).attr("data-address-mode", "edit");
                    if (baseObj.methods && baseObj.methods.editMultiShipAddress) {
                        baseObj.methods.editMultiShipAddress($rootEl);
                    } else {
                        editMultiShipAddress($rootEl);
                    }
                }
            }

            return false;
        });
    },

    isGift: function () {
        $(".gift").on("change", function (e) {
            e.preventDefault();
            var form = $(this).closest("form");

            if (this.checked) {
                $(form).find(".gift-message").removeClass("d-none");
            } else {
                $(form).find(".gift-message").addClass("d-none");
                $(form).find(".gift-message").val("");
            }
        });
    },

    /**
    * Initializes the custom behaviors for shipping
    */
    initShippingCustomBehaviors() {
        customDatePickerHandler();
        deliveryTypeListener();
        handleTabsChange();
        storePickupListener();
        loadGoogleMapsScript();
        openStoreLocatorModal();
        pickupNotification();
        initCustomPicker();
    }
};
