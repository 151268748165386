"use strict";

/**
* Sets a timeout to clear the HTML content of the specified element reference
* @param {jQuery} $elementRef - the jQuery element reference to update
* @param {number} timeOut - the time in milliseconds before clearing the content
* @returns {void}
*/
function setNotificationTimeout($elementRef, timeOut) {
    setTimeout(() => {
        $elementRef.html("");
    }, timeOut);
}

module.exports = {
    createErrorNotification: function (message, $elementRef, hasSetTimeout = false, timeOut = 3000) {
        const errorHtml = `
            <div class="font-light alert alert-danger alert-dismissible valid-cart-error fade show" role="alert">
                ${hasSetTimeout ? "" : `
                <a href="#" type="button" class="btn-close close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true" class="color-white">&times;</span>
                </a>
                `}
                ${message}
            </div>
        `;

        $($elementRef).append(errorHtml);
        if (hasSetTimeout) {
            setNotificationTimeout($elementRef, timeOut);
        }
    },

    createSuccessNotification: function (message, $elementRef, hasSetTimeout = false, timeOut = 3000) {
        const successHtml = `
            <div class="font-light alert alert-success alert-dismissible valid-cart-error fade show" role="alert">
                ${hasSetTimeout ? "" : `
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                `}
                ${message}
            </div>
        `;

        $($elementRef).css({
            "left": "auto",
            "right": "10px"
        });

        $($elementRef).append(successHtml);

        if (hasSetTimeout) {
            setNotificationTimeout($elementRef, timeOut);
        }
    }
};
