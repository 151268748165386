"use strict";
const { methods: { doAddCouponToBasket } } = require("./ahumadaFamilyDiscounts");
const $floatingCoupon = $(".familia-ahumada-coupon-modal");
const STORAGE_KEY = "ahumada-family-coupon";

/**
* Sets the coupon details in the session storage if user is logged in
* When the user is not logged in, the session storage is removed
* @param {boolean} isCheckout - indicates whether the user is in the checkout process
* @returns {void}
*/
function setCouponDetails(isCheckout) {
    const isLoggedIn = $("[data-is-logged-in]").length > 0;

    // if user is not logged in, remove session storage related to coupon
    if (!isCheckout && !isLoggedIn) {
        sessionStorage.removeItem(STORAGE_KEY);
        return;
    }

    const $couponDetails = $(".js-ahumada-family-discounts__coupon_details:first");

    if (!$couponDetails.length) {
        return;
    }

    const couponDetails = $couponDetails.val();
    const coupons = [];

    if (couponDetails) {
        coupons.push(JSON.parse(couponDetails));
    }

    sessionStorage.setItem(STORAGE_KEY, JSON.stringify({
        ...getStorageData(),
        coupons
    }));
}

/**
* Sets the modal as dismissed in the session storage to avoid showing it again
*/
function setModalDismissed() {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify({
        ...getStorageData(),
        isDismissed: true
    }));
}

/**
* Retrieves coupon data from the session storage and parses it into a JavaScript object
* @returns {Object} - the parsed data from the session storage, or an empty object if no data is found or an error occurs
*/
function getStorageData() {
    try {
        const storage = sessionStorage.getItem(STORAGE_KEY);

        return storage ? JSON.parse(storage) : {};
    } catch (_e) {
        return {};
    }
}

/**
* Displays the floating coupon on the page if applicable
* @returns {void}
*/
function showFloatingCoupon() {
    const isCheckoutPage = $("#checkout-main").length > 0;

    setCouponDetails(isCheckoutPage);

    const { isDismissed, coupons } = getStorageData();

    if (isCheckoutPage || isDismissed || !coupons || !coupons.length) {
        return;
    }

    const coupon = coupons[0];
    const $container = $(".js-ahumada-family-floating-coupon");

    $(".js-coupon-src-mobile", $container).attr("srcset", coupon.smallImageUrl);
    $(".js-coupon-image", $container).attr("src", coupon.largeImageUrl).attr("alt", coupon.description);
    $(".js-add-coupon-to-basket", $container).attr("data-url", coupon.url);

    $floatingCoupon.addClass("modal-open").removeClass("d-none");
}

/**
* Displays the coupon added modal based on the device type
*/
function showCouponAddedModal() {
    if (isDesktop()) {
        $(".coupon-added-modal").modal("show");
        return;
    }

    if (!$(".add-coupon-to-cart-messages").length) {
        $("body").append(
            "<div class='add-coupon-to-cart-messages'></div>"
        );
    }

    const $messageContent = $(".js-coupon-added-modal-mobile-template .add-to-basket-alert").clone();

    $(".add-coupon-to-cart-messages")
        .empty()
        .append($messageContent);
}

/**
* Determines if the current device is a desktop
* @returns {boolean} true if the current device is a desktop, false otherwise
*/
function isDesktop() {
    return $(window).width() > 768;
}

/**
* Adds a click event listener to the close button in the cart PLP modal
*/
function closeModalEventListener() {
    $(".js-close-coupon-modal").on("click", () => {
        $(".js-ahumada-family-floating-coupon").modal("hide");
    });
}

/**
* Initializes event listeners for the floating coupon functionality
*/
function initListeners() {
    if (!isDesktop()) {
        $("body").on("click", function closeMobileMessageOnOutsideClick({ target }) {
            const $target = $(target);
            const $messagesContainer = $(".add-coupon-to-cart-messages");
            const isOutsideClick = !$target.closest(".add-to-cart-coupon").length;
            const hasCloseButton = $(".close", $messagesContainer).length;

            if ($messagesContainer.length && isOutsideClick && hasCloseButton) {
                $(".close", $messagesContainer).trigger("click");
            }
        });
    }

    $(".close", $floatingCoupon).on("click", function closeFloatingCoupon() {
        setModalDismissed();
        $floatingCoupon.addClass("d-none");
    });

    $(".js-add-coupon-to-basket", $floatingCoupon).on("click", function addCouponToBasketHandler() {
        const isOnCartPage = $(".cart-page").length;

        doAddCouponToBasket($(this))
            .then(function (data) {
                if (data.error) {
                    console.error(data.errorMessage);
                    return;
                }

                $floatingCoupon.find(".close").trigger("click");
                showCouponAddedModal();

                if (isOnCartPage) {
                    setTimeout(()=>{
                        location.reload();
                    }, 2000);
                }
            });
    });

    closeModalEventListener();
}

module.exports = {
    initListeners: initListeners,
    showFloatingCoupon
};
