window.jQuery = window.$ = require("jquery");
var processInclude = require("base/util");

$(document).ready(function () {
    processInclude(require("./components/menu"));
    processInclude(require("base/components/cookie"));
    processInclude(require("./components/consentTracking"));
    processInclude(require("base/components/footer"));
    processInclude(require("./components/miniCart"));
    processInclude(require("base/components/collapsibleItem"));
    processInclude(require("./components/search"));
    processInclude(require("./components/customSearch"));
    processInclude(require("./components/clientSideValidation"));
    processInclude(require("base/components/countrySelector"));
    processInclude(require("base/components/toolTip"));
    processInclude(require("./product/addToCart"));
    processInclude(require("./product/afterAddToCartModal"));
    processInclude(require("./components/swiperCarousel"));
    processInclude(require("./components/quantity"));
    processInclude(require("./components/handleFooterMenuClick"));
    processInclude(require("./components/zoneSelector"));
    processInclude(require("./components/outOfStockMessage"));
    processInclude(require("./analytics"));
    processInclude(require("./ahumadaFamily/floatingCoupon"));
});

require("slick-carousel/slick/slick.min.js");
require("jquery-mask-plugin/src/jquery.mask.js");
require("base/thirdParty/bootstrap");
require("base/components/spinner");
require("bootstrap/js/src/popover.js");
