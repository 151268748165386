"use strict";

let $quantityDropDown = $(".quantity-select");
let $currentElement;
const _ = require("lodash");

function getCurrentQuantity() {
    var selectedValue = $currentElement.parents(".quantity-input-wrapper").siblings("select.quantity-select").val();

    return Number(selectedValue);
}

function addOrRemoveHeight(messageError) {
    var isMinicartOpen       = $(".popover.popover-bottom.show").length > 0;
    var $minicartProductItem = messageError.closest(".product-line-item .product-line-item-details");

    if (isMinicartOpen && messageError.text()) {
        $minicartProductItem.css("height", "max-content");
    } else {
        $minicartProductItem.css("height", "auto");
    }
}

function updateQuantity(newQuantity) {
    var isSelectedOptionFromModal   = $currentElement.parents("body").hasClass("modal-open");
    var isElementAtQuickView        = $currentElement.is(".js-increase-quantity-pdp-quick-view, .js-decrease-quantity-pdp-quick-view");
    var $currentElementErrorMessage = $currentElement.siblings(".js-quantity-error-message");
    var $hiddenDropdown = $currentElement.parents(".quantity-input-wrapper").siblings("select.quantity-select");

    if (isSelectedOptionFromModal) {
        var $modalQuantityField = $("input[data-is-quick-view=\"true\"]");

        $modalQuantityField.val(newQuantity);
    } else {
        var $repeatedElements = $(".line-item-" + $currentElement.parent().siblings(".quantity-select").attr("data-uuid"));

        $repeatedElements.val(newQuantity);
        $currentElement.siblings(".product-quantity-input").val(newQuantity);
    }

    $currentElementErrorMessage.text("");
    $hiddenDropdown.val(newQuantity);

    if (isElementAtQuickView) {
        $currentElement.parents(".details").siblings(".cart-and-ipay").find(".update-cart-url").data("selected-quantity", newQuantity);
    } else {
        $hiddenDropdown.trigger("change");
    }
}

function isQuantityValid(newQuantity, $element) {
    const $this                     = $element;
    var msgQtt                      = $(".js-error-message-qtt").val();
    var msgMinQtt                   = $(".js-error-message-min-qtt").val();
    var $currentElementErrorMessage = $this.parents(".quantity-input-wrapper").find(".js-quantity-error-message");
    const qttTotal = Number($this.parents(".quantity-input-wrapper").find(".max-order-qtt").attr("value"));
    const MIN_QUANTITY = 1;
    const MAX_QUANTITY = qttTotal ? qttTotal : 10;

    if (newQuantity < MIN_QUANTITY) {
        updateQuantity(MIN_QUANTITY);
        $currentElementErrorMessage.text(msgMinQtt);
        addOrRemoveHeight($currentElementErrorMessage);
        return false;
    }

    if (newQuantity > MAX_QUANTITY) {
        updateQuantity(MAX_QUANTITY);
        $currentElementErrorMessage.text(msgQtt);
        addOrRemoveHeight($currentElementErrorMessage);
        return false;
    }

    $currentElementErrorMessage.text("");
    addOrRemoveHeight($currentElementErrorMessage);
    return true;
}

function validateAndUpdateQuantity(newQuantity, element) {
    if (!isQuantityValid(newQuantity, element)) {
        return;
    }

    updateQuantity(newQuantity);
}

function setInitialQuantity() {
    var $modalContainer           = $(this).find("#editProductModal");
    var isSelectedOptionFromModal = $(this).hasClass("modal-open");

    if (isSelectedOptionFromModal) {
        var selectedValue = $modalContainer.find("select.quantity-select").val();

        $modalContainer.find(".js-quantity-input").val(selectedValue);
    } else {
        $quantityDropDown = $(".quantity-select");

        $.each($quantityDropDown, function () {
            var $qtdField       = $(this).siblings("div.quantity-input-wrapper").find(".js-quantity-input");

            $qtdField.val($(this).val());
        });
    }
}


module.exports = function () {
    const $body = $("body");
    const $quantityInput = $(".js-quantity-input");
    setInitialQuantity();
    $quantityInput.mask("00");

    $body.on("click", ".js-decrease-quantity, .js-decrease-quantity-pdp-quick-view", function () {
        $currentElement     = $(this);
        var currentQuantity = getCurrentQuantity();

        validateAndUpdateQuantity(currentQuantity - 1, $currentElement);
    });

    $body.on("click", ".js-increase-quantity, .js-increase-quantity-pdp-quick-view", function () {
        $currentElement     = $(this);
        var currentQuantity = getCurrentQuantity();

        validateAndUpdateQuantity(currentQuantity + 1, $currentElement);
    });

    $body.on("focus", ".js-quantity-input", function () {
        var $this      = $(this);
        var $container = $this.parents(".product-info").length > 0 ? $this.parents(".product-info") : $this.parents(".product-info-wrapper");

        if ($container.length === 0) {
            $container = $this.parents(".quantity-wrapper");
        }

        if ($this.val()) {
            $this.mask("00");
        } else {
            $this.val($(".quantity-select", $container).val());
        }
    });

    $body.on("keyup", ".js-quantity-input", _.debounce(function (e) {
        var $this                       = $(this);
        var typedQuantity               = Number($this.val());
        var $container                  = $this.parents(".product-info").length > 0 ? $this.parents(".product-info") : $this.parents(".product-info-wrapper");
        var isInvalidValue              = $this.val() === $(".quantity-select", $container).val() || isNaN(Number(e.key));
        var $currentElementErrorMessage = $this.siblings(".js-quantity-error-message");
        const qttTotal = Number($this.parents(".quantity-input-wrapper").find(".max-order-qtt").attr("value"));
        const MIN_QUANTITY = 1;
        const MAX_QUANTITY = qttTotal ? qttTotal : 10;

        if ($container.length === 0) {
            $container = $this.parents(".quantity-wrapper");
        }

        if ($this.val() === "") {
            $("button.add-to-cart").prop("disabled", true);
            $(".js-quantity-error-message", $this.parent()).html($(".js-wrong-quantity-msg").val());
            addOrRemoveHeight($currentElementErrorMessage);
            return;
        } else if (isInvalidValue) {
            return;
        }

        addOrRemoveHeight($currentElementErrorMessage);
        $(".js-quantity-error-message", $this.parent()).empty();
        $currentElement = $this;

        validateAndUpdateQuantity(typedQuantity, $this);

        if ($currentElement.val() > MAX_QUANTITY) {
            $currentElement.val(MAX_QUANTITY);
        } else if ($currentElement.val() < MIN_QUANTITY) {
            $currentElement.val(MIN_QUANTITY);
        }
    }, 500));

    $body.on("focusout", ".js-quantity-input, .js-minicart-input-wrapper", function () {
        var $this                       = $(this);
        var $container                  = $this.parents(".quantity-input-wrapper").length > 0 ? $this.parents(".quantity-input-wrapper") : $this.parents(".product-page-quantity-wrapper");
        var $currentElementErrorMessage = $this.siblings(".js-quantity-error-message");

        if ($container.length === 0) {
            $container = $this.parents(".quantity-wrapper");
        }

        $(".js-quantity-error-message", $container).empty();
        addOrRemoveHeight($currentElementErrorMessage);
    });

    $body.on("editproductmodal:ready", setInitialQuantity);
};
