/* eslint-env jquery */

"use strict";

window.addEventListener("load", () => {
    const Swiper = require("swiper").default;
    const Navigation = require("swiper").Navigation;
    const Pagination = require("swiper").Pagination;
    const Scrollbar = require("swiper").Scrollbar;
    const Thumbs = require("swiper").Thumbs;

    if (!window.swiperCarouselInitialized) {
        const carousels = $(".js-swiper-carousel");

        carousels.each(function () {
            const $swiperWrapper = $(this);
            const swipperWrapperClass = `.js-swiper-carousel[data-id="${$swiperWrapper.data("id")}"]`;
            const thumbnailCarouselElement = $(".image-carrousel__thumbnails").find(".swiper")[0];
            const thumbnailSwiper = thumbnailCarouselElement ? thumbnailCarouselElement.swiper : null;

            let slidesToShow, slidesToShowMobile, spacing;

            try {
                slidesToShow = parseInt($swiperWrapper.data("slides-to-show"), 10) || 5;
                slidesToShowMobile = parseInt($swiperWrapper.data("slides-to-show-mobile"), 10) || 2;
                spacing = parseInt($swiperWrapper.data("spacing"), 10) || 16;
            } catch (e) {
                slidesToShow = 5;
                slidesToShowMobile = 2;
                spacing = 16;
            }

            const loopEnabled = $swiperWrapper.data("loop-enabled");
            const navEnabled = $swiperWrapper.data("navigation-enabled");
            const paginationEnabled = $swiperWrapper.data("pagination-enabled");
            const scrollbarEnabled = $swiperWrapper.data("scroll-enabled");
            const direction = $swiperWrapper.data("direction");
            const clickableSlide = !!$swiperWrapper.data("clickable-slide");
            const syncedCarouselId = $swiperWrapper.data("sync-carousel-id");
            const carouselModules = [];

            if (navEnabled) {
                carouselModules.push(Navigation);
            }

            if (paginationEnabled) {
                carouselModules.push(Pagination);
            }

            if (scrollbarEnabled) {
                carouselModules.push(Scrollbar);
            }

            if (syncedCarouselId) {
                carouselModules.push(Thumbs);
            }

            const carouselOptions = {
                loop: Boolean(loopEnabled),
                modules: carouselModules,
                direction: direction || "horizontal",
                pagination: {
                    el: `${swipperWrapperClass} .js-swiper-pagination`,
                    type: "bullets",
                    clickable: true,
                },
                navigation: {
                    nextEl: `${swipperWrapperClass} .js-swiper-button-next`,
                    prevEl: `${swipperWrapperClass} .js-swiper-button-prev`,
                },
                scrollbar: {
                    el: `${swipperWrapperClass} .js-swiper-scrollbar`,
                },
                slideToClickedSlide: clickableSlide,
                slidesPerView: slidesToShowMobile,
                spaceBetween: spacing,
                breakpoints: {
                    1024: {
                        slidesPerView: slidesToShow,
                        spaceBetween: spacing,
                    },
                },
                ...(syncedCarouselId && {
                    thumbs: {
                        swiper: thumbnailSwiper
                    }
                })
            };

            // eslint-disable-next-line no-unused-vars
            const carouselSwiper = new Swiper(`${swipperWrapperClass} .js-swiper-container`, carouselOptions);

        });

        //Update the global variable so that the Swiper Carousel is not initialized multiple times.
        window.swiperCarouselInitialized = true;
    }
});
