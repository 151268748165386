"use strict";

const { createErrorNotification, createSuccessNotification } = require("../utils/utilsServices");
const $container = $(".ahumada-family-discounts");
const $notificationContainer = $(".notification-alert");

/**
* Adds a coupon to the current basket
* @param {JQuery} $context - the jQuery context object
* @returns {Promise} a Promise that resolves with the data when the coupon is successfully added, or rejects with an error
*/
function doAddCouponToBasket($context) {
    return new Promise((resolve, reject) => {
        const url = $context.data("url");

        if (!url) {
            reject();
            return;
        }

        $.spinner().start();

        $.ajax({
            url: url,
            type: "GET",
            success: function (data) {
                $.spinner().stop();

                resolve(data);
            },
            error: function (err) {
                console.error(err);
                $.spinner().stop();
                reject(err);
            }
        });
    });
}

/**
* Adds a coupon to the current basket upon clicking the add coupon button
* @returns {void}
*/
function addCouponToBasket() {
    $(".js-add-coupon-to-basket", $container).on("click", function addCouponToBasket() {
        doAddCouponToBasket($(this))
            .then(function (data) {
                if (data.error) {
                    createErrorNotification(data.errorMessage, $notificationContainer, true);
                    return;
                }

                createSuccessNotification($notificationContainer.data("coupon-added-message"), $notificationContainer, true);
            });
    });
}

/**
* Activates a promotion when the corresponding button is clicked.
* Used for the interactive promotions, which requires activation before using it.
* @returns {void}
*/
function activatePromotion() {
    $(".js-activate-promotion", $container).on("click", function doPromotionActivation() {
        const $this = $(this);
        const activePromotionText = $this.data("active-promo-resource");
        const isPromotionActive = $this.data("is-opted-in");
        const url = $this.data("url");
        const offerId = $this.data("offerId");

        if (!url) {
            return;
        }

        // If promotion is already active display notification message
        if (isPromotionActive) {
            createErrorNotification($notificationContainer.data("promotion-already-active-message"), $notificationContainer, true);
            return;
        }

        $.spinner().start();

        $.ajax({
            url: url,
            data: { offerId },
            type: "POST",
            success: function (data) {
                $.spinner().stop();

                if (!data.success) {
                    createErrorNotification(data.message, $notificationContainer, true);
                    return;
                }

                createSuccessNotification(data.message, $notificationContainer, true);
                $this.data("is-opted-in", true);
                $this.text(activePromotionText);
            },
            error: function (err) {
                if (err.responseJSON && err.responseJSON.message) {
                    createErrorNotification(err.responseJSON.message, $notificationContainer, true);
                }

                $.spinner().stop();
            }
        });
    });
}

/**
* Initializes the carousel for displaying Ahumada family discounts
*/
function initFamiliaAhumadaCarousel() {
    const $carouselWrapper = $(".ahumada-family-discounts__carousel");
    const showDots = $carouselWrapper.children().length > 1;

    $carouselWrapper.slick({
        mobileFirst: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: showDots,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
        ]
    });
}

module.exports = {
    initFamiliaAhumadaCarousel,
    addCouponToBasket,
    activatePromotion,
    methods: {
        doAddCouponToBasket,
    }
};
