"use strict";

function handleFooterMenuClick() {
    const $footerItemTitle = $(".footer-item .title");

    $footerItemTitle.on("click", function () {
        const $this = $(this);
        const $footerItemCollapsible = $this.parents(".footer-item");
        const isFooterItemCollapsibleActive = !$footerItemCollapsible.hasClass("active");
        const $footerCollapsibleContent = $footerItemCollapsible.find(".content");
        const headerHeight = $(".header-content").height();

        if (isFooterItemCollapsibleActive) {
            const animationDuration = parseFloat($footerCollapsibleContent.css("animation-duration")) * 1000;

            $footerItemTitle.css("scroll-margin-top", headerHeight + "px");

            setTimeout(() => {
                $this[0].scrollIntoView({ behavior: "smooth", block: "start" });
            }, animationDuration);
        }
    });
}

module.exports = {
    handleFooterMenuClick
};
