"use strict";

var formValidation = require("../components/formValidation");
const googlePlaceHelpers = require("ahumada/googlePlaces/googlePlaces");
var utilMask = require("ahumada/utils/masks");
const { methods } = require("../components/dashboardAlert");
const addressSelector = require("./addressSelector");
const { validateAddressOnGoogleGeocoder } = require("../googlePlaces/googlePlaces");

// CONSTANTS
const $rut = $(".form-rut-field");
const $readOnlyFields = $("#rut, #addressId");
const isEditPage = $(".page").data("querystring");


var url;
var isDefault;

const $addressForm = $("form.address-form");

/**
 * Create an alert to display the error message
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = "<div class=\"alert alert-danger alert-dismissible valid-cart-error " +
        "fade show\" role=\"alert\">" +
        "<button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">" +
        "<span aria-hidden=\"true\">&times;</span>" +
        "</button>" + message + "</div>";

    $(".error-messaging").append(errorHtml);
}

/**
* Handle the click event for selecting a default address
* @function handleSelectDefaultAddress
*/
function handleSelectDefaultAddress() {
    $(".js-select-address-btn").on("click", function () {
        const $this = $(this);
        const $currentRadioBtn = $this.find(".ahumada-custom-radio");
        const toggleSelected = !$this.hasClass("selected");
        const $saveAddressBtn = $(".js-save-address-btn");

        // Remove all previous selected addresses
        $(".js-select-address-btn .ahumada-custom-radio").removeClass("selected");

        $currentRadioBtn.toggleClass("selected", toggleSelected);

        $saveAddressBtn.attr("href", $this.attr("data-default-url"));
    });
}

/**
* Initializes the Google Places Autocomplete feature
*/
function initGooglePlacesAutocomplete(inputFields) {

    googlePlaceHelpers.initAutocomplete(inputFields, function () {
        checkIfFieldsAreFilled($addressForm);
    });
}

/**
* Adds the class "readonly" to the read-only fields on the edit page
*/
const addReadOnlyFields = () => {
    if (isEditPage) {
        $readOnlyFields.addClass("readonly").attr("readonly", true);
    }
};

/**
* Attaches event listeners to the form fields
*/
const listenEventsForm = () => {
    $("#rut").on("keydown", (e) => {
        utilMask.maskRUT(e.target);
    });

    $("#phone").on("keydown", (e) => {
        utilMask.maskPhone(e.target);
    });

    $(".address-form input, .address-form select").on("change", () => {
        checkIfFieldsAreFilled($addressForm);
    });

    utilMask.maskRUT($rut);
    addReadOnlyFields();
};

/**
* Checks if all fields in a form are filled
* @param {Object} form - The form element
* @param {boolean} ignoreHiddenFields - Whether to ignore hidden fields
* @returns {boolean} - Whether all fields are filled
*/
const checkIfFieldsAreFilled = (form, ignoreHiddenFields) => {

    const $fields = $(form).find("input:not([type=\"hidden\"]), select");
    let $submitButton = $(form).find("button[type=\"submit\"], .btn-submit");

    if (!$submitButton.length) {
        $submitButton = $(form).closest("form").find("button[type=\"submit\"], .btn-submit");
    }

    let areFieldsValid = true;

    // Remove readonly property to check if fields are filled
    $(".readonly").each(function () {
        $(this).prop("readonly", false);
    });

    $fields.each(function () {
        const isValid = !$(this).prop("validity").valueMissing && (ignoreHiddenFields ? !$(this).is(":hidden") : true);
        if (!isValid) {
            areFieldsValid = false;
            return false; // break out of the loop
        }
    });

    const disable = !areFieldsValid;

    // Enable after validation
    $(".readonly").each(function () {
        $(this).prop("readonly", true);
    });

    $submitButton.prop("disabled", disable);
};

/**
 * Enables the submit button when all fields in a form are filled
 * @param {jQuery} $form - the jQuery object representing the form
 */
function enableSubmitButtonWhenAllFieldsAreFilled($form) {
    checkIfFieldsAreFilled($form);
    $($form).off("change keyup input").on("change keyup input", $($form).find("input, select"), function () {
        checkIfFieldsAreFilled($form);
    });
}

const updateCountryInputValue = () => {
    const autoCompleteCountryInput = $("input#country");

    const stateSelectInput = $("select#country");
    const selectedOption = stateSelectInput.find("option:selected");
    const selectedOptionValue = selectedOption.data("country-name");

    autoCompleteCountryInput.val(selectedOptionValue);
};

/**
* Populate form fields with data
* @param {Object} $form - jQuery form object
* @param {Object} data - key-value pairs of data to populate
* @returns {void}
*/
function populateForm($form, data) {
    Object.keys(data).forEach(key => {
        const $input = $form.find(`input[name$=_${key}], select[name$=_${key}], [data-${key}]`);

        if ($input.length) {
            $input.val(data[key] !== undefined ? data[key] : "");
        }
    });
}

module.exports = {
    removeAddress: function () {
        $(".remove-address").on("click", function (e) {
            e.preventDefault();
            isDefault = $(this).data("default");
            if (isDefault) {
                url = $(this).data("url")
                    + "?addressId="
                    + $(this).data("id")
                    + "&isDefault="
                    + isDefault;
            } else {
                url = $(this).data("url") + "?addressId=" + $(this).data("id");
            }
            $(".product-to-remove").empty().append($(this).data("id"));
        });
    },

    removeAddressConfirmation: function () {
        $(".delete-confirmation-btn").click(function (e) {
            e.preventDefault();
            $.ajax({
                url: url,
                type: "get",
                dataType: "json",
                success: function (data) {
                    $("#uuid-" + data.UUID).remove();
                    if (isDefault) {
                        var addressId = $(".card .address-heading").first().text();
                        var addressHeading = addressId + " (" + data.defaultMsg + ")";
                        $(".card .address-heading").first().text(addressHeading);
                        $(".card .card-make-default-link").first().remove();
                        $(".remove-address").data("default", true);
                        if (data.message) {
                            var toInsert = "<div><h3>" +
                                data.message +
                                "</h3><div>";
                            $(".addressList").after(toInsert);
                        }
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                    }
                    $.spinner().stop();
                }
            });
        });
    },

    submitAddress: function (addressId = "") {
        $("form.address-form").off().submit(async function (e) {
            e.preventDefault();
            var $form                           = $(this);
            const isCartPage                    = $(".cart.cart-page").length;
            const isAddAddressManuallyEnabled = $(".js-manual-address-container").length && !$(".js-manual-address-container").hasClass("d-none");
            let isAddressSelectorValid          = true;
            let isEnteredAddressValid           = true;
            let url                             = $form.attr("action") + addressId;

            $form.spinner().start();
            $("form.address-form").trigger("address:submit", e);
            // Remove previous errors
            $("body").trigger("address:submit:address", true);

            if (isAddAddressManuallyEnabled) {
                addressSelector.methods.copyCityAndStateToAddress();
                isAddressSelectorValid = addressSelector.methods.validateCityAndState();
                isEnteredAddressValid =  isAddressSelectorValid ? await validateAddressOnGoogleGeocoder() : false;
            }

            if (!isAddressSelectorValid || !isEnteredAddressValid) {
                $form.spinner().stop();
                return;
            }

            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: $form.serialize(),
                success: function (data) {
                    $form.spinner().stop();
                    if (!data.success) {
                        formValidation($form, data);
                    } else {
                        if (isCartPage) {
                            window.location.reload();
                            return;
                        }
                        location.href = data.redirectUrl;
                        localStorage.setItem("successMessage", true);
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                    $form.spinner().stop();
                }
            });
        });
    },
    showSuccessMessage: methods.showSuccessfulMessage,
    methods: {
        populateForm
    },
    handleSelectDefaultAddress: handleSelectDefaultAddress,
    initGooglePlacesAutocomplete,
    listenEventsForm,
    updateCountryInputValue,
    enableSubmitButtonWhenAllFieldsAreFilled,
};
