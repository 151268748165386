"use strict";

const carousel = require("base/carousel");
const gtm = require("../gtm");

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find(".product-option")
        .map(function () {
            var $elOption = $(this).find(".options-select");
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find("option[value=\"" + urlValue + "\"]")
                .data("value-id");
            return {
                optionId: $(this).data("option-id"),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var body = $html.find(".choice-of-bonus-product");
    var footer = $html.find(".modal-footer").children();

    return { body: body, footer: footer };
}

/**
 * initialize the carousel for the bonus product popup
 */
function initBonusProductCarousel() {
    $(".bonus-product-item .bonus-product-images").each(function () {
        var currentElement = $(this);
        var sliderConfigs = currentElement.data("slider-config");
        carousel.initCarousel({
            selector : currentElement,
            config   : sliderConfigs
        });
    });
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($el.hasClass("product-tile-add-to-cart") || $el.hasClass("product-search-add-to-cart") || $el.hasClass("ahumada-family-product-promotion-add-to-cart")) {
        return $el.data("pid");
    }

    if ($("#quickViewModal").hasClass("show") && !$(".product-set").length) {
        pid = $($el).closest(".modal-content").find(".product-quickview").data("pid");
    } else if ($(".product-set-detail").length || $(".product-set").length) {
        pid = $($el).closest(".product-detail").find(".product-id").text();
    } else {
        pid = $(".product-detail:not(\".bundle-item\")").data("pid");
    }

    return pid;
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $(".add-to-cart-url").val();
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts($el) {
    if ($el.hasClass("ahumada-family-product-promotion-add-to-cart")) {
        var bundleChildProducts = $($el).closest(".ahumada-family-product-promotion").data("bundled-products");
        if (bundleChildProducts) {
            return JSON.stringify(bundleChildProducts);
        }
    }

    if ($el.hasClass("product-tile-add-to-cart")) {
        var plpBundleChildProducts = $($el).closest(".product-tile").data("bundled-products");
        if (plpBundleChildProducts) {
            return JSON.stringify(plpBundleChildProducts);
        }
    }

    var childProducts = [];
    $(".bundle-item").each(function () {
        childProducts.push({
            pid: $(this).find(".product-id").text(),
            quantity: parseInt($(this).find("label.quantity").data("quantity"), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $(".set-items").length) {
        quantitySelected = $($el).closest(".product-detail").find(".quantity-select");
    } else if ($el && $(".product-bundle").length) {
        var quantitySelectedModal = $($el).closest(".modal-footer").find(".quantity-select");
        var quantitySelectedPDP = $($el).closest(".bundle-footer").find(".quantity-select");
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $(".quantity-select");
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    if ($el.hasClass("product-tile-add-to-cart")) {
        return $el.closest(".product-tile").data("plp-default-quantity");
    } else if ($el.hasClass("product-search-add-to-cart")) {
        return $el.data("search-default-quantity");
    } else if ($el.hasClass("ahumada-family-product-promotion-add-to-cart")) {
        return $el.closest(".ahumada-family-product-promotion").data("default-quantity");
    }

    return getQuantitySelector($el).val();
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $(".minicart").trigger("count:update", response);
    var messageType = response.error ? "alert-danger" : "alert-success";
    const isDesktop = $(window).width() > 768;
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($(".add-to-cart-messages").length === 0) {
            $("body").append(
                "<div class=\"add-to-cart-messages\"></div>"
            );
        }

        if (!isDesktop || response.error) {
            $(".add-to-cart-messages").append(
                `<div class="${messageType} add-to-basket-alert text-center alert alert-dismissible fade show d-flex align-items-center border-none" role="alert">
                ${response.message}
                <button type="button" class="close p-0 ml-auto position-relative" data-dismiss="alert" aria-label="Close">
                    <span class="d-flex" aria-hidden="true">
                        <svg data-id="icon_close" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><rect id="clear_space" width="24" height="24" fill="#fff" opacity="0"/><path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M23.243,20.955l6.905-6.907a1.618,1.618,0,0,0-2.288-2.289l-6.905,6.907L14.049,11.76a1.618,1.618,0,1,0-2.288,2.289l6.905,6.907-6.905,6.907a1.618,1.618,0,0,0,2.288,2.289l6.905-6.907,6.905,6.907a1.618,1.618,0,0,0,2.288-2.289Z" transform="translate(-8.618 -8.622)" fill="#fff"/></svg>
                    </span>
                </button>
                </div>`
            );

            setTimeout(function () {
                $(".add-to-basket-alert").remove();
            }, 3000);
        }
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: "GET",
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $(".modal-body").spinner().start();

    if ($("#chooseBonusProductModal").length !== 0) {
        $("#chooseBonusProductModal").remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = "<!-- Modal -->"
        + "<div class=\"modal fade\" id=\"chooseBonusProductModal\" tabindex=\"-1\" role=\"dialog\">"
        + "<span class=\"enter-message sr-only\" ></span>"
        + "<div class=\"modal-dialog choose-bonus-product-dialog\" "
        + "data-total-qty=\"" + data.maxBonusItems + "\""
        + "data-UUID=\"" + data.uuid + "\""
        + "data-pliUUID=\"" + data.pliUUID + "\""
        + "data-addToCartUrl=\"" + data.addToCartUrl + "\""
        + "data-pageStart=\"0\""
        + "data-pageSize=\"" + data.pageSize + "\""
        + "data-moreURL=\"" + data.showProductsUrlRuleBased + "\""
        + "data-bonusChoiceRuleBased=\"" + data.bonusChoiceRuleBased + "\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header epo-bg-color-light-gray\">"
        + "    <h2 class=\"modal-title epo-like-a-h4 align-items-center mb-0 epo-color-dark-gray\">" + data.labels.selectprods + "</h2>"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span aria-hidden=\"true\">&times;</span>"
        + "        <span class=\"sr-only\"> </span>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\"></div>"
        + "<div class=\"modal-footer\"></div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
    $(".modal-body").spinner().start();

    $.ajax({
        url: bonusUrl,
        method: "GET",
        dataType: "json",
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $("#chooseBonusProductModal .modal-body").empty();
            $("#chooseBonusProductModal .enter-message").text(response.enterDialogMessage);
            $("#chooseBonusProductModal .modal-header .close .sr-only").text(response.closeButtonText);
            $("#chooseBonusProductModal .modal-body").html(parsedHtml.body);
            $("#chooseBonusProductModal .modal-footer").html(parsedHtml.footer);
            $("#chooseBonusProductModal").modal("show");
            $("#chooseBonusProductModal").on("shown.bs.modal", initBonusProductCarousel);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    addToCart: function () {
        $(document).on("click", "button.product-tile-add-to-cart, button.product-search-add-to-cart, button.ahumada-family-product-promotion-add-to-cart", function (e) {
            e.preventDefault();
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            const isCartPage = $(".cart-page").length;
            const element = $(this);

            $("body").trigger("product:beforeAddToCart", this);

            if ($(".set-items").length && $(this).hasClass("add-to-cart-global")) {
                setPids = [];

                $(".product-detail").each(function () {
                    if (!$(this).hasClass("product-set-detail")) {
                        setPids.push({
                            pid: $(this).find(".product-id").text(),
                            qty: $(this).find(".quantity-select").val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest(".product-detail");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".quick-view-dialog").find(".product-detail");
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts($(this)),
                quantity: getQuantitySelected($(this))
            };

            if (!$(".bundle-item").length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger("updateAddToCartFormData", form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: "POST",
                    data: form,
                    success: function (data) {
                        if (!data.error) {
                            $("body").trigger("product:afterAddToCart", { data, pid: form.pid, quantity: +form.quantity });
                        }
                        handlePostCartAdd(data);

                        if (element.hasClass("ahumada-family-product-promotion-add-to-cart")) {
                            gtm.addToCart(element.closest(".js-ahumada-family-product-promotion"), "ahumadaFamilyProductPromotion");
                        } else if (!element.hasClass("product-search-add-to-cart")) {
                            gtm.addToCart(element.closest(".product-tile"), "tile");
                        }

                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);

                        // If on cart Page Reload Page
                        if (isCartPage) {
                            setTimeout(()=> {
                                window.location.reload();
                            }, 1000);
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    }
};
